import { t } from "@lingui/macro";
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@resume-plus/ui";
import { CircleUserRound } from "lucide-react";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { useLogout } from "@/client/services/auth";

export const UserMenu = () => {
  const { logout } = useLogout();
  const navigate = useNavigate();

  const onSettings = useCallback(() => {
    navigate("/settings");
  }, []);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="secondary" size="icon" className="rounded-full">
          <CircleUserRound className="size-5" />
          <span className="sr-only">Toggle user menu</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>My Account</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={onSettings}>{t`Settings`}</DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={() => logout()}>{t`Logout`}</DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
