import { ResumeExample } from "@resume-plus/dto";
import { ResumeSamplesResponse } from "@resume-plus/schema";
import axios from "axios";

// const BASE_URL = import.meta.env.VITE_PUBLIC_STRAPI_API_URL;
const BASE_URL = "https://content.resumeplus.app/api";

export const fetchResumeSamples = async (): Promise<ResumeExample[]> => {
  try {
    const response = await axios.get<ResumeSamplesResponse>(
      "/resume-examples?populate=preview,tags&pagination[pageSize]=100",
      {
        baseURL: BASE_URL,
      },
    );

    const data = response.data.data.map((datum) => {
      const previewUrl = datum.attributes.preview.data[0]?.attributes.url ?? "";
      return {
        id: datum.id,
        title: datum.attributes.title,
        category: datum.attributes.category,
        description: datum.attributes.description,
        tags: datum.attributes.tags.data.map((tag) => tag.attributes.name),
        thumbnailUrl: previewUrl,
        config: datum.attributes.config,
      };
    });

    return data as ResumeExample[];
  } catch (error) {
    console.error("Error fetching resume samples:", error);

    return [];
  }
};
