import { t } from "@lingui/macro";
import { createId } from "@paralleldrive/cuid2";
import { CoverLetterDto, ResumeDto } from "@resume-plus/dto";
import { CustomSectionGroup, defaultSection, SectionKey } from "@resume-plus/schema";
import { removeItemInLayout } from "@resume-plus/utils";
import _set from "lodash.set";
import { temporal, TemporalState } from "zundo";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { useStoreWithEqualityFn } from "zustand/traditional";

import { debouncedUpdateCover } from "../services/cover/update";
import { debouncedUpdateResume } from "../services/resume";

type DocumentDto = ResumeDto | CoverLetterDto;

type DocumentStore<T extends DocumentDto> = {
  document: T;

  // Actions
  setValue: (path: string, value: unknown) => void;

  // Custom Section Actions (only for Resume)
  addSection?: () => void;
  removeSection?: (sectionId: SectionKey) => void;
};

export const isResumeDto = (doc: DocumentDto): doc is ResumeDto => {
  return doc.type === "resume";
};

export const useResumeStore = create<DocumentStore<ResumeDto>>()(
  devtools(
    temporal(
      immer((set) => ({
        document: {} as ResumeDto,
        setValue: (path, value) => {
          set((state) => {
            if (path === "visibility") {
              state.document.visibility = value as "public" | "private";
            } else if (path === "title") {
              state.document.title = value as string;
            } else {
              state.document.data = _set(state.document.data, path, value);
            }
            void debouncedUpdateResume(JSON.parse(JSON.stringify(state.document)));
          });
        },
        addSection: () => {
          set((state) => {
            const section: CustomSectionGroup = {
              ...defaultSection,
              id: createId(),
              name: t`Custom Section`,
              items: [],
            };
            const lastPageIndex = state.document.data.metadata.layout.length - 1;
            state.document.data.metadata.layout[lastPageIndex][0].push(`custom.${section.id}`);
            state.document.data = _set(
              state.document.data,
              `sections.custom.${section.id}`,
              section,
            );
            void debouncedUpdateResume(JSON.parse(JSON.stringify(state.document)));
          });
        },
        removeSection: (sectionId: SectionKey) => {
          set((state) => {
            if (sectionId.startsWith("custom.")) {
              const id = sectionId.split("custom.")[1];
              removeItemInLayout(sectionId, state.document.data.metadata.layout);
              // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
              delete state.document.data.sections.custom[id];
              void debouncedUpdateResume(JSON.parse(JSON.stringify(state.document)));
            }
          });
        },
      })),
      {
        limit: 100,
        wrapTemporal: (fn) => devtools(fn),
        partialize: ({ document }) => ({ document }),
      },
    ),
  ),
);

export const useCoverLetterStore = create<DocumentStore<CoverLetterDto>>()(
  devtools(
    temporal(
      immer((set) => ({
        document: {} as CoverLetterDto,
        setValue: (path, value) => {
          set((state) => {
            if (path === "visibility") {
              state.document.visibility = value as "public" | "private";
            } else if (path === "title") {
              state.document.title = value as string;
            } else {
              state.document.data = _set(state.document.data, path, value);
            }
            void debouncedUpdateCover(JSON.parse(JSON.stringify(state.document)));
          });
        },
      })),
      {
        limit: 100,
        wrapTemporal: (fn) => devtools(fn),
        partialize: ({ document }) => ({ document }),
      },
    ),
  ),
);

export const useTemporalResumeStore = <T>(
  selector: (state: TemporalState<{ document: ResumeDto }>) => T,
  equality?: (a: T, b: T) => boolean,
) => useStoreWithEqualityFn(useResumeStore.temporal, selector, equality);

export const useTemporalCoverLetterStore = <T>(
  selector: (state: TemporalState<{ document: CoverLetterDto }>) => T,
  equality?: (a: T, b: T) => boolean,
) => useStoreWithEqualityFn(useCoverLetterStore.temporal, selector, equality);
