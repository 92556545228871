type NewResumeOptionProps = {
  icon: React.ReactNode;
  title: string;
  description: string;
  onClick: () => void;
};

export const NewResumeOption = ({ icon, title, description, onClick }: NewResumeOptionProps) => {
  return (
    <div>
      <button
        className="flex w-full items-center gap-2 py-2 text-sm font-medium text-gray-800 hover:text-[#7363F3]"
        onClick={onClick}
      >
        {icon}
        {title}
      </button>
      <p className="ml-6 text-sm text-gray-500">{description}</p>
    </div>
  );
};
