/* eslint-disable lingui/no-unlocalized-strings */
import {
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  Progress,
} from "@resume-plus/ui";
import { Check, Crown, Download, Loader2 } from "lucide-react";
import React from "react";

type DownloadModalProps = {
  loading: boolean;
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  startDownload: () => void;
  isPremium?: boolean;
  type: string;
};

const DownloadModal = ({
  loading,
  isOpen,
  onOpenChange,
  startDownload,
  isPremium = false,
  type = "resume",
}: DownloadModalProps) => {
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    if (loading) {
      setProgress(0);
      const timer = setInterval(() => {
        setProgress((prev) => {
          if (prev >= 100) {
            clearInterval(timer);
            return 100;
          }
          // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
          return prev + 2;
        });
      }, 100);

      return () => {
        clearInterval(timer);
      };
    }
  }, [loading]);

  const handleClose = (open: boolean) => {
    if (loading) return;
    onOpenChange(open);
  };

  const renderInitialContent = () => (
    <div className="flex flex-col items-center space-y-6 py-6">
      {isPremium ? (
        <>
          <div className="rounded-full bg-purple-100 p-3">
            <Download className="size-8 text-purple-600" />
          </div>
          <div className="space-y-2 text-center">
            <h3 className="text-lg font-semibold">Ready to Download</h3>
            <p className="text-sm text-slate-500">
              Your {type} will be generated in high quality PDF format
            </p>
          </div>
          <div className="flex flex-col space-y-2">
            <div className="flex items-center gap-2 text-sm text-slate-700">
              <Check className="size-4 text-green-500" />
              <span>Professional formatting</span>
            </div>
            <div className="flex items-center gap-2 text-sm text-slate-700">
              <Check className="size-4 text-green-500" />
              <span>Custom fonts included</span>
            </div>
            <div className="flex items-center gap-2 text-sm text-slate-700">
              <Check className="size-4 text-green-500" />
              <span>High resolution graphics</span>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="rounded-full bg-amber-100 p-3">
            <Crown className="size-8 text-amber-600" />
          </div>
          <div className="space-y-2 text-center">
            <h3 className="text-lg font-semibold">Upgrade for Best Quality</h3>
            <p className="text-sm text-slate-500">
              Get access to premium features and enhanced quality
            </p>
          </div>
          <div className="w-full space-y-4">
            <div className="rounded-lg border border-slate-200 p-4">
              <h4 className="mb-2 font-medium">Premium Features:</h4>
              <ul className="space-y-2">
                <li className="flex items-center gap-2 text-sm text-slate-700">
                  <Check className="size-4 text-green-500" />
                  <span>Professional templates</span>
                </li>
                <li className="flex items-center gap-2 text-sm text-slate-700">
                  <Check className="size-4 text-green-500" />
                  <span>Custom fonts and colors</span>
                </li>
                <li className="flex items-center gap-2 text-sm text-slate-700">
                  <Check className="size-4 text-green-500" />
                  <span>AI-powered content suggestions</span>
                </li>
                <li className="flex items-center gap-2 text-sm text-slate-700">
                  <Check className="size-4 text-green-500" />
                  <span>Multiple {type} versions</span>
                </li>
              </ul>
            </div>
          </div>
        </>
      )}

      <div className="flex w-full gap-3">
        {!isPremium && (
          <Button
            className="flex-1 bg-gradient-to-r from-purple-600 to-blue-600 text-white hover:from-purple-700 hover:to-blue-700"
            onClick={() => {
              /* Add upgrade navigation */
            }}
          >
            Upgrade Now
          </Button>
        )}
        <Button
          className={`flex-1 ${isPremium ? "bg-purple-600 hover:bg-purple-700" : "bg-slate-600 hover:bg-slate-700"}`}
          onClick={startDownload}
        >
          {isPremium ? "Download PDF" : "Continue with Basic"}
        </Button>
      </div>
    </div>
  );

  const renderLoadingContent = () => (
    <div className="flex flex-col items-center space-y-6 py-6">
      <div className="relative">
        <div className="absolute -inset-1.5 animate-pulse rounded-full bg-purple-100" />
        <Loader2 className="relative size-12 animate-spin text-purple-600" />
      </div>

      <div className="w-full space-y-2 px-4">
        <Progress value={progress} className="h-2" />
        <p className="text-center text-sm text-slate-500">
          {isPremium
            ? `Generating your professional PDF ${type}...`
            : `Generating your basic PDF ${type}...`}
        </p>
      </div>

      <div className="space-y-2 text-center">
        <p className="text-sm font-medium text-slate-900">
          {progress < 30 && "Optimizing Layout..."}
          {progress >= 30 && progress < 60 && "Formatting Content..."}
          {progress >= 60 && progress < 90 && "Preparing PDF..."}
          {progress >= 90 && "Almost Done..."}
        </p>
        <p className="text-xs text-slate-500">Please don't close this window</p>
      </div>
    </div>
  );

  return (
    <Dialog open={isOpen} onOpenChange={handleClose}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle className="text-center text-xl font-semibold">
            {!loading && (isPremium ? "Download PDF" : "Premium Features")}
            {loading && "Preparing Your PDF"}
          </DialogTitle>
        </DialogHeader>

        {loading ? renderLoadingContent() : renderInitialContent()}
      </DialogContent>
    </Dialog>
  );
};

export default DownloadModal;
