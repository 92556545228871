/* eslint-disable lingui/no-unlocalized-strings */
import { t } from "@lingui/macro";
import { useBreakpoint } from "@resume-plus/hooks";
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
} from "@resume-plus/ui";
import { cn } from "@resume-plus/utils";
import { Menu as MenuIcon } from "lucide-react";
import React from "react";
import { Link } from "react-router-dom";

import { Icon } from "@/client/components/icon";
import { useLogout } from "@/client/services/auth";
import { useBuilderStore } from "@/client/stores/builder";
import { useDialog } from "@/client/stores/dialog";

const resumeNav = [
  {
    title: "Create New Resume",
    type: "resume",
    description: "Create new resume from scratch",
  },
  {
    title: "Create Cover Letter",
    type: "cover",
    description: "Create a new cover letter",
  },
];

const howToGuideNav = [
  {
    title: "How-to Guide",
    href: "/guide/create-professional-resume",
    description: "Step-by-step guide to creating a professional resume",
  },
  {
    title: "Resume Samples",
    href: "/guide/resume-samples",
    description: "Samples of professional-looking resumes",
  },
  {
    title: "Cover Letter Samples",
    href: "/guide/cover-letter-samples",
    description: "Samples of professional-looking cover letters",
  },
];

export function BuilderNavigationMenu() {
  const { breakpoint } = useBreakpoint();
  const { builderType } = useBuilderStore();
  const endpoint = builderType === "resume" ? "/dashboard" : "/dashboard?tab=cover-letters";
  const { open } = useDialog("resume");
  const { open: openCover } = useDialog("cover");

  const { logout } = useLogout();

  return breakpoint === "sm" || breakpoint === "xs" ? (
    <div className="flex w-full justify-between align-middle">
      <Link to={endpoint} className="mt-3">
        <Icon size={48} />
      </Link>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="h-full">
            <MenuIcon />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          {resumeNav.map((item) => (
            <DropdownMenuItem
              key={item.title}
              onClick={() => {
                if (item.type === "resume") {
                  open("create");
                } else if (item.type === "cover") {
                  openCover("create");
                }
              }}
            >
              {item.title}
            </DropdownMenuItem>
          ))}
          <DropdownMenuSeparator />
          {howToGuideNav.map((item) => (
            <DropdownMenuItem key={item.title}>
              <Link to={item.href}>{item.title}</Link>
            </DropdownMenuItem>
          ))}
          <DropdownMenuSeparator />
          <DropdownMenuItem>
            <Link to="/account">My account</Link>
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem onClick={() => logout()}>{t`Logout`}</DropdownMenuItem>
          {/* <DropdownMenuItem>
            <Link to="/docs">Resume Checker</Link>
          </DropdownMenuItem> */}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  ) : (
    <NavigationMenu>
      <Link to={endpoint} className="-ml-2 flex size-12">
        <Icon className="" size={48} />
      </Link>
      <NavigationMenuList>
        <NavigationMenuItem className="ml-5 font-inter text-[15px] font-medium">
          <NavigationMenuLink>
            <Link to="/">Documents</Link>
          </NavigationMenuLink>
          {/* <NavigationMenuContent>
            <ul className="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px]">
              {resumeNav.map((component) => (
                <ListItem key={component.title} title={component.title} href={component.href}>
                  {component.description}
                </ListItem>
              ))}
            </ul>
          </NavigationMenuContent> */}
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuTrigger className="font-inter text-[15px] font-medium">
            Guide
          </NavigationMenuTrigger>
          <NavigationMenuContent>
            <ul className="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px] ">
              {howToGuideNav.map((component) => (
                <ListItem key={component.title} title={component.title} href={component.href}>
                  {component.description}
                </ListItem>
              ))}
            </ul>
          </NavigationMenuContent>
        </NavigationMenuItem>
        {/* <NavigationMenuItem>
          <Link to="/docs">
            <NavigationMenuLink className={navigationMenuTriggerStyle()}>
              Resume Checker
            </NavigationMenuLink>
          </Link>
        </NavigationMenuItem> */}
      </NavigationMenuList>
    </NavigationMenu>
  );
}

const ListItem = React.forwardRef<React.ElementRef<"a">, React.ComponentPropsWithoutRef<"a">>(
  ({ className, title, children, ...props }, ref) => (
    <li>
      <NavigationMenuLink asChild>
        <a
          ref={ref}
          className={cn(
            "block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground",
            className,
          )}
          {...props}
        >
          <div className="text-sm font-medium leading-none">{title}</div>
          <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">{children}</p>
        </a>
      </NavigationMenuLink>
    </li>
  ),
);
