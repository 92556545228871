/* eslint-disable lingui/no-unlocalized-strings */
import { Alert, AlertDescription, AlertTitle, LoadingButton } from "@resume-plus/ui";
import { RefreshCw } from "lucide-react";
import React from "react";

type ResumeReloadPromptProps = {
  onReload: () => void;
};

const ResumeReloadPrompt: React.FC<ResumeReloadPromptProps> = ({ onReload }) => {
  const [loading, setLoading] = React.useState(false);

  const onReloadClicked = () => {
    onReload();
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 3000);
  };

  return (
    <Alert className="mx-auto my-4 max-w-md border-border bg-background">
      <RefreshCw className="size-4 text-primary" />
      <AlertTitle className="pt-1 text-primary">Can't see your resume?</AlertTitle>
      <AlertDescription className="flex items-center justify-between">
        <span className="text-accent-foreground">It might need a quick refresh.</span>
        <LoadingButton
          loading={loading}
          variant="outline"
          size="sm"
          className="border-border bg-background text-accent-foreground hover:bg-secondary"
          onClick={onReloadClicked}
        >
          Reload
        </LoadingButton>
      </AlertDescription>
    </Alert>
  );
};

export default ResumeReloadPrompt;
