import { Tabs, TabsList, TabsTrigger } from "@resume-plus/ui";
import { Loader2 } from "lucide-react";
import React from "react";

type DashboardTabsProps = {
  totalResumes: number;
  totalCovers: number;
  resumeLoading: boolean;
  coverLoading: boolean;
  currentTab: string;
  setCurrentTab: (tab: string) => void;
};

export const DashboardTabs: React.FC<DashboardTabsProps> = ({
  totalResumes,
  totalCovers,
  resumeLoading,
  coverLoading,
  currentTab,
  setCurrentTab,
}) => {
  return (
    <Tabs value={currentTab} className="w-full" onValueChange={setCurrentTab}>
      <TabsList>
        <TabsTrigger value="resumes" className="whitespace-nowrap">
          <span className="hidden items-center gap-2 sm:inline-flex">
            Resumes {resumeLoading ? "" : `(${totalResumes})`}
            {resumeLoading && <Loader2 className="size-4 animate-spin" />}
          </span>
          <span className="sm:hidden">Resumes</span>
        </TabsTrigger>
        <TabsTrigger value="cover-letters" className="whitespace-nowrap">
          <span className="hidden items-center gap-2 sm:inline-flex">
            Cover Letters {coverLoading ? "" : `(${totalCovers})`}
            {coverLoading && <Loader2 className="size-4 animate-spin" />}
          </span>
          <span className="sm:hidden">Letters</span>
        </TabsTrigger>
      </TabsList>
    </Tabs>
  );
};
