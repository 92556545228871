import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@resume-plus/ui";
import { Input } from "@resume-plus/ui";
import { Plus } from "lucide-react";
import { Search } from "lucide-react";
import React from "react";

import { useDialog } from "@/client/stores/dialog";

type ActionBarProps = {
  search?: string;
  setSearch: (search: string | undefined) => void;
};

export const ActionBar: React.FC<ActionBarProps> = ({ search, setSearch }) => {
  const { open } = useDialog("resume");
  const { open: openCover } = useDialog("cover");

  return (
    <div className="flex w-full flex-col gap-4 md:w-auto md:flex-row md:items-center">
      <div className="flex items-center gap-4">
        <div className="relative w-full md:w-[120px] lg:w-[240px]">
          <Search className="absolute left-2 top-1/2 size-4 -translate-y-1/2 text-gray-500" />
          <Input
            defaultValue={search}
            className="pl-8"
            placeholder="Search"
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              const value = e.currentTarget.value;
              setSearch(value);
            }}
          />
        </div>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button className="whitespace-nowrap bg-primary">
              <Plus className="mr-2 size-4" />
              <span className="hidden md:inline">Create New</span>
              <span className="md:hidden">New</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem
              onClick={() => {
                open("create");
              }}
            >
              Create Resume
            </DropdownMenuItem>
            <DropdownMenuItem
              onClick={() => {
                openCover("create");
              }}
            >
              Create Cover Letter
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      {/* <ViewToggle /> */}
    </div>
  );
};
