import { z } from "zod";

import { customFieldSchema } from "../../resume/basics";
import { urlSchema } from "../../resume/shared";

export const coverLetterBasicsSchema = z.object({
  name: z.string(),
  email: z.literal("").or(z.string().email()),
  phone: z.string(),
  address: z.string(),
  url: urlSchema,
  customFields: z.array(customFieldSchema),
});

export const defaultCoverLetterHeader = {
  name: "",
  email: "",
  phone: "",
  address: "",
  url: { label: "", href: "" },
  customFields: [],
};
