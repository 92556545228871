import { CoverLetterDto, ImportCoverLetterDto } from "@resume-plus/dto";
import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { axios } from "@/client/libs/axios";
import { queryClient } from "@/client/libs/query-client";

export const importCover = async (data: ImportCoverLetterDto) => {
  const response = await axios.post<
    CoverLetterDto,
    AxiosResponse<CoverLetterDto>,
    ImportCoverLetterDto
  >("/cover/import", data);

  return response.data;
};

export const useImportCover = () => {
  const {
    error,
    isPending: loading,
    mutateAsync: importCoverFn,
  } = useMutation({
    mutationFn: importCover,
    onSuccess: (data) => {
      queryClient.setQueryData<CoverLetterDto>(["cover", { id: data.id }], data);

      queryClient.setQueryData<CoverLetterDto[]>(["covers"], (cache) => {
        if (!cache) return [data];
        return [...cache, data];
      });
    },
  });

  return { importCover: importCoverFn, loading, error };
};
