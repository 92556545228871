/* eslint-disable lingui/no-unlocalized-strings */
import { t } from "@lingui/macro";
import { coverLetterContentSchema } from "@resume-plus/schema";
import { Input, Label } from "@resume-plus/ui";

import { useCoverLetterStore } from "@/client/stores/resume";

// import { CustomFieldsSection } from "./custom/section";
import { getSectionIcon } from "./shared/section-icon";

export const EmployerDetails = () => {
  const setValue = useCoverLetterStore((state) => state.setValue);
  const content = useCoverLetterStore((state) => state.document.data.content);

  return (
    <section id="employer-details" className="grid gap-y-6">
      <header className="flex items-center justify-between">
        <div className="flex items-center gap-x-2">
          {getSectionIcon("employer-details")}
          <h2 className="line-clamp-1 text-xl font-semibold font-inter">{t`Employer Details`}</h2>
        </div>
      </header>

      <main className="grid gap-2 sm:grid-cols-2">
        <div className="grid gap-4 sm:col-span-2 sm:grid-cols-2">
          <div className="space-y-1.5">
            <Label htmlFor="content.company">{t`Company Name`}</Label>
            <Input
              id="content.company"
              value={content.company}
              hasError={
                !coverLetterContentSchema
                  .pick({ company: true })
                  .safeParse({ company: content.company }).success
              }
              onChange={(event) => {
                setValue("content.company", event.target.value);
              }}
            />
          </div>

          <div className="space-y-1.5">
            <Label htmlFor="content.jobTitle">{t`Hiring Manager Name`}</Label>
            <Input
              id="content.recipient"
              value={content.recipient}
              placeholder="Hiring Manager"
              hasError={
                !coverLetterContentSchema
                  .pick({ recipient: true })
                  .safeParse({ recipient: content.recipient }).success
              }
              onChange={(event) => {
                setValue("content.recipient", event.target.value);
              }}
            />
          </div>
        </div>
      </main>
    </section>
  );
};
