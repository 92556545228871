/* eslint-disable lingui/no-unlocalized-strings */
import { t } from "@lingui/macro";
import {
  coverLetterBasicsSchema as basicsSchema,
  coverLetterContentSchema,
} from "@resume-plus/schema";
import { Input, Label } from "@resume-plus/ui";

import { useCoverLetterStore } from "@/client/stores/resume";

// import { CustomFieldsSection } from "./custom/section";
import { getSectionIcon } from "./shared/section-icon";

export const PeronalDetailsSection = () => {
  const setValue = useCoverLetterStore((state) => state.setValue);
  const basics = useCoverLetterStore((state) => state.document.data.basics);
  const content = useCoverLetterStore((state) => state.document.data.content);

  return (
    <section id="personal-details" className="grid gap-y-6">
      <header className="flex items-center justify-between">
        <div className="flex items-center gap-x-2">
          {getSectionIcon("basics")}
          <h2 className="line-clamp-1 text-xl font-semibold font-inter">{t`Personal Details`}</h2>
        </div>
      </header>

      <main className="grid gap-2 sm:grid-cols-2">
        <div className="grid gap-4 sm:col-span-2 sm:grid-cols-2">
          <div className="space-y-1.5">
            <Label htmlFor="basics.name">{t`Full Name`}</Label>
            <Input
              id="basics.name"
              value={basics.name}
              placeholder={basics.name}
              hasError={!basicsSchema.pick({ name: true }).safeParse({ name: basics.name }).success}
              onChange={(event) => {
                setValue("basics.name", event.target.value);
              }}
            />
          </div>

          <div className="space-y-1.5">
            <Label htmlFor="content.jobTitle">{t`Job Title`}</Label>
            <Input
              id="content.jobTitle"
              value={content.jobTitle}
              placeholder="e.g. Financial Analyst"
              hasError={
                !coverLetterContentSchema
                  .pick({ jobTitle: true })
                  .safeParse({ jobTitle: content.jobTitle }).success
              }
              onChange={(event) => {
                setValue("content.jobTitle", event.target.value);
              }}
            />
          </div>

          <div className="space-y-1.5">
            <Label htmlFor="basics.address">{t`Address`}</Label>
            <Input
              id="basics.address"
              value={basics.address}
              placeholder="Address"
              hasError={
                !basicsSchema.pick({ address: true }).safeParse({ address: basics.address }).success
              }
              onChange={(event) => {
                setValue("basics.address", event.target.value);
              }}
            />
          </div>

          <div className="space-y-1.5">
            <Label htmlFor="basics.email">{t`Email`}</Label>
            <Input
              id="basics.email"
              placeholder="john.doe@example.com"
              value={basics.email}
              hasError={
                !basicsSchema.pick({ email: true }).safeParse({ email: basics.email }).success
              }
              onChange={(event) => {
                setValue("basics.email", event.target.value);
              }}
            />
          </div>
          <div className="space-y-1.5">
            <Label htmlFor="basics.phone">{t`Phone`}</Label>
            <Input
              id="basics.phone"
              placeholder="+1 (123) 4567 7890"
              value={basics.phone}
              onChange={(event) => {
                setValue("basics.phone", event.target.value);
              }}
            />
          </div>
        </div>
      </main>
    </section>
  );
};
