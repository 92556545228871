import { Button } from "@resume-plus/ui";
import { ChevronLeft, ChevronRight, MoreHorizontal } from "lucide-react";
import { useMemo } from "react";

type PaginationProps = {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
};

export const Pagination = ({ currentPage, totalPages, onPageChange }: PaginationProps) => {
  const getPageNumbers = useMemo(() => {
    const pages: (number | string)[] = [];

    // Always show first page
    pages.push(1);

    if (currentPage > 3) {
      pages.push("...");
    }

    // Show pages around current page
    for (
      let i = Math.max(2, currentPage - 1);
      i <= Math.min(totalPages - 1, currentPage + 1);
      i++
    ) {
      if (i === 1 || i === totalPages) continue;
      pages.push(i);
    }

    if (currentPage < totalPages - 2) {
      pages.push("...");
    }

    // Always show last page if there's more than one page
    if (totalPages > 1) {
      pages.push(totalPages);
    }

    return pages;
  }, [currentPage, totalPages]);

  return (
    <div className="mt-6 flex flex-wrap items-center justify-center gap-2 sm:justify-end">
      {/* Previous button */}
      <Button
        variant="outline"
        size="sm"
        disabled={currentPage === 1}
        className="size-8 p-0 sm:size-8"
        aria-label="Previous page"
        onClick={() => {
          onPageChange(currentPage - 1);
        }}
      >
        <ChevronLeft className="size-4" />
      </Button>

      {/* Desktop view */}
      <div className="hidden items-center gap-1 sm:flex">
        {Array.from({ length: totalPages }).map((_, idx) => (
          <Button
            key={idx + 1}
            variant={currentPage === idx + 1 ? "primary" : "outline"}
            size="sm"
            className="size-8 p-0"
            onClick={() => {
              onPageChange(idx + 1);
            }}
          >
            {idx + 1}
          </Button>
        ))}
      </div>

      {/* Mobile view */}
      <div className="flex items-center gap-1 sm:hidden">
        {getPageNumbers.map((pageNumber, idx) =>
          pageNumber === "..." ? (
            <Button
              key={`ellipsis-${idx}`}
              disabled
              variant="ghost"
              size="sm"
              className="size-8 cursor-default p-0"
            >
              <MoreHorizontal className="size-4" />
            </Button>
          ) : (
            <Button
              key={pageNumber}
              variant={currentPage === pageNumber ? "primary" : "outline"}
              size="sm"
              className="size-8 p-0"
              onClick={() => {
                onPageChange(pageNumber as number);
              }}
            >
              {pageNumber}
            </Button>
          ),
        )}
      </div>

      {/* Current page indicator for mobile */}
      <div className="text-sm text-gray-500 sm:hidden">
        Page {currentPage} of {totalPages}
      </div>

      {/* Next button */}
      <Button
        variant="outline"
        size="sm"
        disabled={currentPage === totalPages}
        className="size-8 p-0 sm:size-8"
        aria-label="Next page"
        onClick={() => {
          onPageChange(currentPage + 1);
        }}
      >
        <ChevronRight className="size-4" />
      </Button>
    </div>
  );
};

export default Pagination;
