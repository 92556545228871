import { useMutation, useQuery } from "@tanstack/react-query";

import { COVER_PREVIEW_KEY } from "@/client/constants/query-keys";
import { toast } from "@/client/hooks/use-toast";
import { axios } from "@/client/libs/axios";

export const previewCover = async (data: { id: string }) => {
  const response = await axios.get(`/cover/print/${data.id}/preview`);

  return response.data;
};

export const useCoverPreview = (id: string) => {
  const {
    error,
    isPending: loading,
    data,
  } = useQuery({
    queryKey: [COVER_PREVIEW_KEY, { id }],
    queryFn: () => previewCover({ id }),
  });

  return { url: data?.url, loading, error };
};

export const useCoverPreviewAsync = () => {
  const {
    error,
    isPending: loading,
    mutateAsync: previewCoverFn,
  } = useMutation({
    mutationFn: previewCover,
    onSuccess: () => {
      toast({
        variant: "success",
        title: `Generated Preview successfully!`,
      });
    },
    onError: (error) => {
      const message = error.message;

      toast({
        variant: "error",
        title: `Oops, the server returned an error.`,
        description: message,
      });
    },
  });

  return { previewCover: previewCoverFn, loading, error };
};
