"use client";

import { ResumeExample } from "@resume-plus/dto";
import { useBreakpoint } from "@resume-plus/hooks";
import { Loader, Search } from "lucide-react";
import { useCallback, useEffect, useState } from "react";

import {
  Badge,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  Input,
  ScrollArea,
} from "../";

const useBackButton = (callback: () => void) => {
  useEffect(() => {
    const onPopState = () => {
      callback();
    };

    window.addEventListener("popstate", onPopState);
    return () => {
      window.removeEventListener("popstate", onPopState);
    };
  }, [callback]);
};

export type ResumeSamplesProps = {
  resumeExamples: ResumeExample[];
  onTemplateSelected: (example: ResumeExample | null) => void;
};

export const ResumeSamples: React.FC<ResumeSamplesProps> = ({
  resumeExamples,
  onTemplateSelected,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedExample, setSelectedExample] = useState<ResumeExample | null>(null);
  const [imageLoading, setImageLoading] = useState(true);
  const { isMobile } = useBreakpoint();

  useEffect(() => {
    setImageLoading(true);
  }, [selectedExample]);

  const openDialog = useCallback(
    (example: ResumeExample) => {
      setSelectedExample(example);
      if (isMobile) {
        // eslint-disable-next-line no-restricted-globals
        history.pushState({ dialog: true }, "");
      }
    },
    [isMobile],
  );

  const closeDialog = useCallback(() => {
    setSelectedExample(null);
    setImageLoading(true);
    if (isMobile && window.history.state?.dialog) {
      window.history.back();
    }
  }, [isMobile]);

  useBackButton(closeDialog);

  const filteredExamples = resumeExamples.filter((example) => {
    const searchLower = searchQuery.toLowerCase();
    return (
      (example.title.toLowerCase().includes(searchLower) ||
        example.category?.toLowerCase().includes(searchLower)) ??
      example.description?.toLowerCase().includes(searchLower) ??
      example.tags?.some((tag) => tag.toLowerCase().includes(searchLower))
    );
  });

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="container mx-auto px-4 py-8">
        <h1 className="mb-8 text-center text-4xl font-bold">Resume Examples</h1>
        <div className="relative mx-auto mb-12 max-w-xl">
          <Input
            type="search"
            placeholder="Search resume examples..."
            className="w-full pl-12"
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
            }}
          />
          <Search className="absolute left-4 top-3 size-5 text-gray-400" />
        </div>
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {filteredExamples.map((example) => (
            <Card
              key={example.id}
              className="transition-shadow hover:shadow-lg"
              onClick={() => {
                openDialog(example);
              }}
            >
              <CardHeader>
                <CardTitle>{example.title}</CardTitle>
              </CardHeader>
              <CardContent className="group relative">
                <div className="relative mb-4 aspect-[3/4] cursor-pointer">
                  <img
                    src={example.thumbnailUrl}
                    alt={`Preview of ${example.title}`}
                    className="rounded-md object-contain shadow-custom"
                  />
                  <div className="absolute inset-0 flex items-center justify-center rounded-md bg-gray-500 bg-opacity-50 opacity-0 transition-opacity group-hover:opacity-100">
                    <Button variant="secondary">Start with this template</Button>
                  </div>
                </div>
                <p className="mb-4 text-sm text-muted-foreground">{example.description}</p>
                <div className="flex flex-wrap gap-2">
                  {example.tags?.map((tag) => (
                    <Badge key={tag} outline className="text-xs" variant="info">
                      {tag}
                    </Badge>
                  ))}
                </div>
              </CardContent>
            </Card>
          ))}
        </div>
        {filteredExamples.length === 0 && (
          <p className="mt-8 text-center text-muted-foreground">
            No resume examples found matching your search.
          </p>
        )}
      </div>
      <Dialog open={!!selectedExample} onOpenChange={closeDialog}>
        <DialogContent className="dialog-content h-[calc(100vh-10px)] max-w-6xl p-0">
          <ScrollArea className="h-full">
            <div className="p-6">
              <DialogHeader>
                <DialogTitle className="text-2xl">{selectedExample?.title}</DialogTitle>
                <DialogDescription className="text-lg">
                  {selectedExample?.category}
                </DialogDescription>
              </DialogHeader>
              <div className="mt-6 grid grid-cols-1 gap-6 md:grid-cols-3">
                <div className="col-span-2">
                  <div className="relative rounded-md bg-gray-100 ">
                    {imageLoading && selectedExample && (
                      <div className="absolute inset-0 flex aspect-[3/4] items-center justify-center">
                        <Loader className="size-8 animate-spin text-primary" />
                      </div>
                    )}
                    <img
                      src={selectedExample?.thumbnailUrl}
                      alt={`Detailed view of ${selectedExample?.title}`}
                      className={`size-full rounded-md shadow-custom ${
                        imageLoading ? "opacity-0" : "opacity-100"
                      } transition-opacity`}
                      onLoad={() => {
                        setImageLoading(false);
                      }}
                    />
                  </div>
                </div>
                <div className="flex flex-col justify-between">
                  <div>
                    <h3 className="mb-2 text-lg font-semibold">Description</h3>
                    <p className="mb-4 text-muted-foreground">{selectedExample?.description}</p>
                    <h3 className="mb-2 text-lg font-semibold">Key Features</h3>
                    <ul className="mb-4 list-inside list-disc">
                      {selectedExample?.tags?.map((tag) => (
                        <li key={tag} className="text-muted-foreground">
                          {tag}
                        </li>
                      ))}
                    </ul>
                    <Button
                      className="w-full"
                      onClick={() => {
                        onTemplateSelected(selectedExample);
                      }}
                    >
                      Use This Template
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </ScrollArea>
        </DialogContent>
      </Dialog>
    </div>
  );
};
