import { t } from "@lingui/macro";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";

import { useCovers } from "@/client/services/cover";
import { useResumes } from "@/client/services/resume";

import { ActionBar } from "./action-bar";
import { CoverGrid } from "./cover-grid";
import { AppHeader } from "./dashboard-header";
import { DashboardTabs } from "./dashboard-tabs";
import { ResumeGrid } from "./resume-grid";

export default function Dashboard() {
  const { resumes, loading } = useResumes();
  const { covers, loading: loadingCover } = useCovers();

  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const initialTab = queryParams.get("tab") ?? "resumes";

  const [currentTab, setCurrentTab] = useState<string>(initialTab);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState<string | undefined>();

  useEffect(() => {
    setCurrentPage(1);

    const params = new URLSearchParams(location.search);
    params.set("tab", currentTab);
    navigate({ search: params.toString() }, { replace: true });
  }, [currentTab, location.search, navigate]);

  return (
    <>
      <Helmet>
        <title>
          {t`Resumes`} - {t`Resume Studio`}
        </title>
      </Helmet>
      <div className="min-h-screen bg-background">
        <AppHeader />
        <div className="container mx-auto space-y-6 p-4 sm:p-6">
          <header className="space-y-4">
            <div className="flex flex-col gap-4 sm:flex-row sm:items-center sm:justify-between">
              <div className="w-full sm:max-w-[600px]">
                <DashboardTabs
                  totalResumes={resumes?.length ?? 0}
                  totalCovers={covers?.length ?? 0}
                  resumeLoading={loading}
                  coverLoading={loadingCover}
                  currentTab={currentTab}
                  setCurrentTab={setCurrentTab}
                />
              </div>
              <ActionBar search={search} setSearch={setSearch} />
            </div>
          </header>
          {currentTab === "resumes" ? (
            <ResumeGrid
              filter={search}
              resumes={resumes}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          ) : (
            <CoverGrid
              filter={search}
              covers={covers}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          )}
        </div>
      </div>
    </>
  );
}
