import { z } from "zod";

export const defaultCoverLayout = [[["header", "content"]]];

export const coverLetterMetadataSchema = z.object({
  template: z.string().default("default"),
  layout: z.array(z.array(z.array(z.string()))).default(defaultCoverLayout),
  css: z.object({
    value: z.string().default(""),
    visible: z.boolean().default(false),
  }),
  page: z.object({
    margin: z.number().default(18),
    format: z.enum(["a4", "letter"]).default("a4"),
    options: z.object({
      pageNumbers: z.boolean().default(false),
      breakLine: z.boolean().optional().default(true),
    }),
  }),
  theme: z.object({
    background: z.string().default("#ffffff"),
    text: z.string().default("#000000"),
    primary: z.string().default("#dc2626"),
  }),
  typography: z.object({
    font: z.object({
      family: z.string().default("IBM Plex Serif"),
      subset: z.string().default("latin"),
      variants: z.array(z.string()).default(["regular"]),
      size: z.number().default(14),
    }),
    lineHeight: z.number().default(1.5),
    hideIcons: z.boolean().optional().default(false),
    underlineLinks: z.boolean().default(false),
  }),
  notes: z.string().default(""),
});

export type CoverMetadata = z.infer<typeof coverLetterMetadataSchema>;

export const defaultCoverLetterMetadata: CoverMetadata = {
  template: "default",
  layout: [[["header", "content"]]],
  css: {
    value: "",
    visible: false,
  },
  page: {
    margin: 18,
    format: "a4",
    options: {
      pageNumbers: false,
      breakLine: false,
    },
  },
  theme: {
    background: "#ffffff",
    text: "#000000",
    primary: "#dc2626",
  },
  typography: {
    font: {
      family: "IBM Plex Serif",
      subset: "latin",
      variants: ["regular", "italic", "600"],
      size: 14,
    },
    lineHeight: 1.5,
    underlineLinks: true,
    hideIcons: false,
  },
  notes: "",
};
