import { CoverLetterDto, UpdateCoverLetterDto } from "@resume-plus/dto";
import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import debounce from "lodash.debounce";

import { axios } from "@/client/libs/axios";
import { queryClient } from "@/client/libs/query-client";

export const updateCover = async (data: UpdateCoverLetterDto) => {
  const response = await axios.patch<
    CoverLetterDto,
    AxiosResponse<CoverLetterDto>,
    UpdateCoverLetterDto
  >(`/cover/${data.id}`, data);

  queryClient.setQueryData<CoverLetterDto>(["cover", { id: response.data.id }], response.data);

  queryClient.setQueryData<CoverLetterDto[]>(["covers"], (cache) => {
    if (!cache) return [response.data];
    return cache.map((cover) => {
      if (cover.id === response.data.id) return response.data;
      return cover;
    });
  });

  return response.data;
};

export const debouncedUpdateCover = debounce(updateCover, 500);

export const useUpdateCover = () => {
  const {
    error,
    isPending: loading,
    mutateAsync: updateCoverFn,
  } = useMutation({
    mutationFn: updateCover,
  });

  return { updateCover: updateCoverFn, loading, error };
};
