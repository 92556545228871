"use client";

import { useBreakpoint } from "@resume-plus/hooks";
import { Button } from "@resume-plus/ui";
import {
  ArrowLeft,
  ChevronLeft,
  ChevronRight,
  Layout,
  RotateCcw,
  ZoomIn,
  ZoomOut,
} from "lucide-react";
import { useCallback, useEffect, useState } from "react";

import { useBuilderStore } from "@/client/stores/builder";
import { usePreviewStore } from "@/client/stores/preview";

export const PreviewHeader = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [zoom, setZoom] = useState(100);
  const sheet = useBuilderStore((state) => state.sheet);
  const { isDesktop } = useBreakpoint();

  const handleDownloadPdf = usePreviewStore((state) => state.handleOpenDownloadPdf);
  const handleSelectTemplate = usePreviewStore((state) => state.handleSelectTemplate);

  const frameRef = useBuilderStore((state) => state.frame.ref);

  const onZoomIn = () => frameRef?.contentWindow?.postMessage({ type: "ZOOM_IN" }, "*");
  const onZoomOut = () => frameRef?.contentWindow?.postMessage({ type: "ZOOM_OUT" }, "*");
  const onResetView = () => frameRef?.contentWindow?.postMessage({ type: "RESET_VIEW" }, "*");
  const onCenterView = () => frameRef?.contentWindow?.postMessage({ type: "CENTER_VIEW" }, "*");

  const handlePageChange = (pageNumber: number) => {
    frameRef?.contentWindow?.postMessage({
      type: "SET_CURRENT_PAGE",
      payload: {
        pageNumber,
      },
    });
  };

  const handleZoom = (zoomIn: boolean) => {
    // const newZoom = zoomIn ? Math.min(zoom + 10, 200) : Math.max(zoom - 10, 50);
    // setZoom(newZoom);
    // frameRef?.contentWindow?.postMessage({
    //   type: "SET_ZOOM",
    //   payload: {
    //     zoom: newZoom,
    //   },
    // });
    if (zoomIn) {
      onZoomIn();
    } else {
      onZoomOut();
    }
  };

  const handleMessage = useCallback((event: MessageEvent) => {
    if (event.data.type === "CURRENT_PAGE") {
      setCurrentPage(event.data.payload.pageNumber);
      setTotalPages(event.data.payload.totalPages);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [handleMessage]);

  return (
    <div className="absolute inset-x-0 top-0 z-50 bg-slate-500/90 font-sans text-base leading-normal backdrop-blur-sm">
      <div className="flex flex-wrap items-center justify-between px-1 py-3 sm:flex-nowrap lg:justify-evenly">
        <div className="order-1 flex items-center">
          {!isDesktop && (
            <Button
              variant="ghost"
              size="icon"
              className="mr-2 text-white hover:bg-slate-600/50 lg:hidden"
              onClick={() => {
                sheet.right.setOpen(false);
              }}
            >
              <ArrowLeft className="size-4 lg:size-5" />
              <span className="sr-only">Back</span>
            </Button>
          )}
          <Button
            variant="ghost"
            className="hidden text-white hover:bg-slate-600/50 sm:flex"
            onClick={() => {
              isDesktop ? handleSelectTemplate(true) : sheet.bottom.setOpen(true);
            }}
          >
            <Layout className="mr-2 size-4 lg:size-5" />
            Select template
          </Button>
          <div className="hidden items-center space-x-2 sm:flex">
            <Button
              variant="ghost"
              size="icon"
              className="text-white hover:bg-slate-600/50"
              onClick={() => {
                handleZoom(false);
              }}
            >
              <ZoomOut className="size-4 lg:size-5" />
              <span className="sr-only">Zoom Out</span>
            </Button>
            <Button
              variant="ghost"
              size="icon"
              className="hidden text-white hover:bg-slate-600/50 xl:flex"
              onClick={() => onResetView()}
            >
              <RotateCcw className="size-4 lg:size-5" />
              <span className="sr-only">Reset Zoom</span>
            </Button>
            <Button
              variant="ghost"
              size="icon"
              className="text-white hover:bg-slate-600/50"
              onClick={() => {
                handleZoom(true);
              }}
            >
              <ZoomIn className="size-4 lg:size-5" />
              <span className="sr-only">Zoom In</span>
            </Button>
          </div>
        </div>

        <div className="order-2 mx-4 hidden items-center justify-center rounded-lg bg-slate-700/50 text-white sm:flex">
          <Button
            variant="ghost"
            size="icon"
            disabled={currentPage === 0}
            className="text-white hover:bg-slate-600/50 disabled:opacity-50"
            onClick={() => {
              handlePageChange(currentPage - 1);
            }}
          >
            <ChevronLeft className="size-5" />
          </Button>
          <span className="min-w-[20px] text-center text-sm font-medium">
            {totalPages === 0 ? "--" : `${currentPage + 1} / ${totalPages}`}
          </span>
          <Button
            variant="ghost"
            size="icon"
            disabled={currentPage === totalPages - 1}
            className="text-white hover:bg-slate-600/50 disabled:opacity-50"
            onClick={() => {
              handlePageChange(currentPage + 1);
            }}
          >
            <ChevronRight className="size-5" />
          </Button>
        </div>

        <div className="order-3 flex items-center space-x-2 sm:order-2">
          <Button
            variant="ghost"
            className="text-white hover:bg-slate-600/50 sm:hidden"
            onClick={() => {
              isDesktop ? handleSelectTemplate(true) : sheet.bottom.setOpen(true);
            }}
          >
            <Layout className="mr-2 size-4" />
            Template
          </Button>
          <Button
            className="bg-primary text-white hover:bg-blue-600"
            onClick={() => {
              handleDownloadPdf(true);
            }}
          >
            <span className="hidden sm:inline">Download PDF</span>
            <span className="sm:hidden">Download</span>
          </Button>
        </div>
      </div>
    </div>
  );
};
