/* eslint-disable lingui/no-unlocalized-strings */
import { t } from "@lingui/macro";
import { useBreakpoint } from "@resume-plus/hooks";

import { useToast } from "@/client/hooks/use-toast";
import { usePrintCover } from "@/client/services/cover";
import { useBuilderStore } from "@/client/stores/builder";
import { useCoverLetterStore, useTemporalCoverLetterStore } from "@/client/stores/resume";

import Toolbar from "../../resume/_components/toolbar-items";

const openInNewTab = (url: string) => {
  const win = window.open(url, "_blank");
  if (win) win.focus();
};

export const BuilderToolbar = () => {
  const { toast } = useToast();
  const { isMobile } = useBreakpoint();
  const frameRef = useBuilderStore((state) => state.frame.ref);
  const setValue = useCoverLetterStore((state) => state.setValue);
  const sheet = useBuilderStore((state) => state.sheet);
  const id = useCoverLetterStore((state) => state.document.id);
  const isPublic = useCoverLetterStore((state) => state.document.visibility === "public");
  const pageOptions = useCoverLetterStore((state) => state.document.data.metadata.page.options);
  const undo = useTemporalCoverLetterStore((state) => state.undo);
  const redo = useTemporalCoverLetterStore((state) => state.redo);

  const { printCover, loading } = usePrintCover();

  const onPrint = async () => {
    const { url } = await printCover({ id });

    openInNewTab(url);
  };

  const onLayout = () => {
    sheet.bottom.setOpen(true);
  };

  const onCopy = async () => {
    const { url } = await printCover({ id });
    await navigator.clipboard.writeText(url);

    toast({
      variant: "success",
      title: t`A link has been copied to your clipboard.`,
      description: t`Anyone with this link can view and download the resume. Share it on your profile or with recruiters.`,
    });
  };

  const onZoomIn = () => frameRef?.contentWindow?.postMessage({ type: "ZOOM_IN" }, "*");
  const onZoomOut = () => frameRef?.contentWindow?.postMessage({ type: "ZOOM_OUT" }, "*");
  const onResetView = () => frameRef?.contentWindow?.postMessage({ type: "RESET_VIEW" }, "*");
  const onCenterView = () => frameRef?.contentWindow?.postMessage({ type: "CENTER_VIEW" }, "*");

  return (
    <Toolbar
      isMobile={isMobile}
      undo={undo}
      redo={redo}
      pageOptions={pageOptions}
      setValue={setValue}
      isPublic={isPublic}
      loading={loading}
      onZoomIn={onZoomIn}
      onZoomOut={onZoomOut}
      onResetView={onResetView}
      onCenterView={onCenterView}
      onCopy={onCopy}
      onPrint={onPrint}
      onLayout={onLayout}
    />
  );
};
