/* eslint-disable lingui/no-unlocalized-strings */
import { UploadSimple } from "@phosphor-icons/react";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Button,
  ResponsiveDialog,
  ResponsiveDialogContent,
  ResponsiveDialogFooter,
} from "@resume-plus/ui";
import { PictureOptionsProps } from "@resume-plus/utils";
import React, { useCallback, useState } from "react";
import { FileWithPath, useDropzone } from "react-dropzone";

import { useUploadImage } from "@/client/services/storage";
import { useResumeStore } from "@/client/stores/resume";

import { PictureOptions } from "./options";

const accept = {
  "image/*": [],
};

type PictureOptionsKeys =
  | keyof PictureOptionsProps
  | `effects.${keyof PictureOptionsProps["effects"]}`;

export const PictureSection = () => {
  const setLocalValue = (key: PictureOptionsKeys, value: string | number | boolean) => {
    setLocalPictureProps((prevPicture) => {
      const updatedPicture = { ...prevPicture };
      if (key.startsWith("effects.")) {
        const effectKey = key.split(".")[1] as keyof PictureOptionsProps["effects"];
        return {
          ...prevPicture,
          effects: {
            ...prevPicture.effects,
            [effectKey]: value as boolean,
          },
        };
      } else {
        return {
          ...updatedPicture,
          [key]: value as unknown,
        };
      }
    });
  };

  const { uploadImage } = useUploadImage();
  const setResumeValue = useResumeStore((state) => state.setValue);
  const resumePicture = useResumeStore((state) => state.document.data.basics.picture);
  const [localPicture, setLocalPictureProps] = useState<PictureOptionsProps>(resumePicture);

  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<(FileWithPath & { preview?: string }) | null>(
    null,
  );

  const onDrop = useCallback((acceptedFiles: FileWithPath[]) => {
    const file = acceptedFiles[0];
    const fileWithPreview = Object.assign(file, {
      preview: URL.createObjectURL(file),
    });
    setSelectedFile(fileWithPreview);
    setModalOpen(true);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept,
    multiple: false,
  });

  const handleSave = async () => {
    if (selectedFile) {
      const response = await uploadImage(selectedFile);
      setLocalValue("url", response.data);
      const temp = {
        ...localPicture,
        url: response.data,
      };
      setResumeValue("basics.picture", temp);
      setModalOpen(false);
      setSelectedFile(null);
    } else {
      setResumeValue("basics.picture", localPicture);
      setModalOpen(false);
    }
  };

  const handleDelete = () => {
    setLocalValue("url", "");
    setResumeValue("basics.picture.url", "");
    setSelectedFile(null);
    setModalOpen(false);
  };

  const getImageStyles = () => {
    const styles: React.CSSProperties = {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      borderRadius: `${localPicture.borderRadius}px`,
      filter: localPicture.effects.grayscale ? "grayscale(100%)" : "none",
      border: localPicture.effects.border ? "2px solid currentColor" : "none",
      display: localPicture.effects.hidden ? "none" : "block",
      aspectRatio: localPicture.aspectRatio,
    };
    return styles;
  };

  React.useEffect(() => {
    return () => {
      if (selectedFile?.preview) {
        URL.revokeObjectURL(selectedFile.preview);
      }
    };
  }, [selectedFile]);

  return (
    <div className="flex items-center gap-x-4">
      <div className="group relative">
        <Avatar
          {...getRootProps()}
          className="size-36 cursor-pointer overflow-hidden ring-2 ring-slate-200 ring-offset-2"
        >
          <input {...getInputProps()} />
          <AvatarImage src={localPicture.url} alt="Profile picture" className="object-cover" />
          <AvatarFallback>
            <div className="flex gap-x-2">
              <UploadSimple className="mt-1" />
              <p>Upload Photo</p>
            </div>
          </AvatarFallback>
        </Avatar>

        {localPicture.url && (
          <div
            className="absolute inset-0 flex cursor-pointer items-center justify-center rounded-full bg-background/30 opacity-0 transition-opacity group-hover:opacity-100"
            onClick={() => {
              setModalOpen(true);
            }}
          ></div>
        )}
      </div>

      <ResponsiveDialog open={isModalOpen} onOpenChange={setModalOpen}>
        <ResponsiveDialogContent>
          <div
            className="relative mx-auto overflow-hidden bg-slate-100"
            style={{
              width: `${localPicture.size}px`,
              height: "auto",
              borderRadius: `${localPicture.borderRadius}px`,
            }}
          >
            {selectedFile?.preview && (
              <img
                src={selectedFile.preview}
                alt="Upload preview"
                className="relative z-20 object-cover"
                style={getImageStyles()}
              />
            )}
            {localPicture.url && !selectedFile && (
              <img
                src={localPicture.url}
                alt="Current profile picture"
                className="relative z-20 object-cover"
                style={getImageStyles()}
              />
            )}
          </div>
          <div className="pt-8">
            <PictureOptions picture={localPicture} setValue={setLocalValue} />
          </div>
          <ResponsiveDialogFooter className="flex justify-end gap-3 lg:gap-0 pt-5">
            <Button type="button" variant="error" onClick={handleDelete}>
              Delete
            </Button>
            <Button type="button" onClick={handleSave}>
              Save Changes
            </Button>
          </ResponsiveDialogFooter>
        </ResponsiveDialogContent>
      </ResponsiveDialog>
    </div>
  );
};

export default PictureSection;
