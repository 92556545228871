export enum ErrorMessage {
  InvalidCredentials = "InvalidCredentials",
  UserAlreadyExists = "UserAlreadyExists",
  SecretsNotFound = "SecretsNotFound",
  OAuthUser = "OAuthUser",
  InvalidResetToken = "InvalidResetToken",
  InvalidVerificationToken = "InvalidVerificationToken",
  EmailAlreadyVerified = "EmailAlreadyVerified",
  TwoFactorNotEnabled = "TwoFactorNotEnabled",
  TwoFactorAlreadyEnabled = "TwoFactorAlreadyEnabled",
  InvalidTwoFactorCode = "InvalidTwoFactorCode",
  InvalidTwoFactorBackupCode = "InvalidTwoFactorBackupCode",
  InvalidBrowserConnection = "InvalidBrowserConnection",
  DocumentSlugAlreadyExists = "DocumentSlugAlreadyExists",
  DocumentNotFound = "DocumentNotFound",
  DocumentLocked = "DocumentLocked",
  DocumentPrinterError = "DocumentPrinterError",
  DocumentPreviewError = "DocumentPreviewError",
  SomethingWentWrong = "SomethingWentWrong",
}
