import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const triggerDownload = (url: string) => {
  const fileName = url.split("/").pop() ?? "resume.pdf";
  const a = document.createElement("a");
  a.href = url;
  a.setAttribute("download", fileName);
  a.target = "_blank";
  document.body.append(a);
  a.click();
  a.remove();
};
