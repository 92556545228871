import { kebabCase } from "@resume-plus/utils";
import { createZodDto } from "nestjs-zod/dto";
import { z } from "nestjs-zod/z";

export const createCoverLetterSchema = z.object({
  title: z.string().min(1),
  slug: z.string().min(1).transform(kebabCase).optional(),
  visibility: z.enum(["public", "private"]).default("private"),
});

export class CreateCoverLetterDto extends createZodDto(createCoverLetterSchema) {}
