import { CoverLetterDto } from "@resume-plus/dto";

import { axios } from "@/client/libs/axios";

export const findCoverById = async (data: { id: string }) => {
  const response = await axios.get<CoverLetterDto>(`/cover/${data.id}`);

  return response.data;
};

export const findCoverByUsernameSlug = async (data: { username: string; slug: string }) => {
  const response = await axios.get<CoverLetterDto>(`/cover/public/${data.username}/${data.slug}`);

  return response.data;
};
