/* eslint-disable tailwindcss/no-custom-classname */
import { t } from "@lingui/macro";
import { ScrollArea, Separator } from "@resume-plus/ui";
import { useRef } from "react";

import { EmployerDetails } from "./sections/employer-details";
import { PeronalDetailsSection } from "./sections/personal-details";
import { SectionIcon } from "./sections/shared/section-icon";
import { SummarySection } from "./sections/summary";

export const LeftSidebar = () => {
  const containterRef = useRef<HTMLDivElement | null>(null);

  const scrollIntoView = (selector: string) => {
    const section = containterRef.current?.querySelector(selector);
    section?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="flex bg-secondary-accent/30">
      <div className="hidden basis-12 flex-col items-center justify-center gap-y-4 bg-secondary-accent/30 py-4 sm:flex">
        <SectionIcon
          id="personal-details"
          name={t({
            message: "Basics",
            context: "The basics section of a resume consists of Full Name, Location etc.",
          })}
          onClick={() => {
            scrollIntoView("#personal-details");
          }}
        />
        <SectionIcon
          id="employer-details"
          name={t({
            message: "Employer Details",
          })}
          onClick={() => {
            scrollIntoView("#employer-details");
          }}
        />
        <SectionIcon
          id="summary"
          name={t({
            message: "Letter Details",
          })}
          onClick={() => {
            scrollIntoView("#summary");
          }}
        />
      </div>

      <ScrollArea orientation="vertical" className="h-[calc(100vh-110px)] flex-1">
        <div ref={containterRef} className="grid gap-y-6 p-6 @container/left">
          <PeronalDetailsSection />
          <Separator />
          <EmployerDetails />
          <Separator />
          <SummarySection />
        </div>
      </ScrollArea>
    </div>
  );
};
