import { CoverLetterDto, CreateCoverLetterDto } from "@resume-plus/dto";
import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { axios } from "@/client/libs/axios";
import { queryClient } from "@/client/libs/query-client";

export const createCover = async (data: CreateCoverLetterDto) => {
  const response = await axios.post<
    CoverLetterDto,
    AxiosResponse<CoverLetterDto>,
    CreateCoverLetterDto
  >("/cover", data);

  return response.data;
};

export const useCreateCover = () => {
  const {
    error,
    isPending: loading,
    mutateAsync: createCoverFn,
  } = useMutation({
    mutationFn: createCover,
    onSuccess: (data) => {
      queryClient.setQueryData<CoverLetterDto>(["cover", { id: data.id }], data);

      queryClient.setQueryData<CoverLetterDto[]>(["covers"], (cache) => {
        if (!cache) return [data];
        return [...cache, data];
      });
    },
  });

  return { createCover: createCoverFn, loading, error };
};
