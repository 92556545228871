/* eslint-disable lingui/no-unlocalized-strings */
import { useBreakpoint } from "@resume-plus/hooks";
import { Sheet, SheetContent } from "@resume-plus/ui";
import { cn, triggerDownload } from "@resume-plus/utils";
import { useCallback, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

import { usePrintResume } from "@/client/services/resume";
import { BuilderTab, useBuilderStore } from "@/client/stores/builder";
import { usePreviewStore } from "@/client/stores/preview";
import { useResumeStore } from "@/client/stores/resume";

import DownloadModal from "../_components/download-dialog";
import { PreviewHeader } from "../_components/preview-header";
import { BuilderToolbar } from "./_components/toolbar";
import { LeftSidebar } from "./sidebars/left";
import ProjectInfo from "./sidebars/left/project-info";
import { RightSidebar } from "./sidebars/right";
import { TemplateDialog } from "./sidebars/right/sections/template-dialog";

const onOpenAutoFocus = (event: Event) => {
  event.preventDefault();
};

const OutletSlot = () => {
  const { isDesktop } = useBreakpoint();

  return (
    <>
      <PreviewHeader />
      <div className="absolute inset-0 mt-[40px] lg:mt-[68px]">
        <Outlet />
      </div>

      {!isDesktop && <BuilderToolbar />}
    </>
  );
};

export const ResumeBuilderLayout = () => {
  const { isDesktop } = useBreakpoint();

  const sheet = useBuilderStore((state) => state.sheet);
  const title = useResumeStore((state) => state.document.title);
  const setValue = useResumeStore((state) => state.setValue);
  const setBuilderType = useBuilderStore((state) => state.setBuilderType);
  const [currentSection, setCurrentSection] = useState<string | undefined>();
  const openDownloadPdf = usePreviewStore((state) => state.openDownloadPdf);
  const handleDownloadPdf = usePreviewStore((state) => state.handleOpenDownloadPdf);

  useEffect(() => {
    setBuilderType("resume");
  }, []);

  const currentBuilderTab = useBuilderStore((state) => state.currentBuilderTab);
  const setCurrentBuilderTab = useBuilderStore((state) => state.setCurrentBuilderTab);

  const handleTabChange = (value: string) => {
    setCurrentBuilderTab(value as unknown as BuilderTab);
  };

  const id = useResumeStore((state) => state.document.id);

  const { printResume, loading } = usePrintResume();

  const onPrint = async () => {
    const { url } = await printResume({ id });

    triggerDownload(url);
  };

  const handleMessage = useCallback(
    (event: MessageEvent) => {
      let delay = 0;
      switch (event.data.type) {
        case "SECTION_SELECTED": {
          if (currentBuilderTab === "layout") {
            handleTabChange("fill-in");
            delay = 300;
          }
          setTimeout(() => {
            setCurrentSection(event.data.payload.sectionId);
          }, delay);

          break;
        }
      }
    },
    [currentBuilderTab, currentSection],
  );

  const onScrollFinished = (side: "left" | "right", lastSection: string) => {
    const currentSectionSelector = `#${currentSection}`;
    if (currentSectionSelector === lastSection) {
      setCurrentSection(undefined);
    }
  };

  useEffect(() => {
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [handleMessage]);

  const onTitleUpdate = (value: string) => {
    if (value === title) return;
    setValue("title", value);
  };

  if (isDesktop) {
    return (
      <div className="flex h-screen w-full flex-col">
        {/* <header className="z-[200] flex h-16 gap-4 border-b bg-background px-4 md:px-6">
          <BuilderNavigationMenu />
          <div className="flex w-full items-center justify-end gap-4 md:ml-auto md:gap-2 lg:gap-4">
            <UserMenu />
          </div>
        </header> */}

        <div className="flex h-full flex-row overflow-hidden">
          <div className="flex h-full min-w-[560px] max-w-[760px] flex-[0_0_50%] flex-col overflow-hidden">
            <div className="relative z-[100] h-12 flex-none">
              <ProjectInfo
                projectName={title}
                activeTab={currentBuilderTab}
                onTabChange={handleTabChange}
                onProjectNameChange={onTitleUpdate}
              />
            </div>
            <div
              className={cn("z-10 mt-5 flex-1 overflow-y-hidden bg-background")}
              id="left-sidebar"
            >
              {currentBuilderTab === "fill-in" && (
                <LeftSidebar startAtSection={currentSection} onScrollFinished={onScrollFinished} />
              )}
              {currentBuilderTab === "layout" && <RightSidebar />}
            </div>
          </div>
          <div className="relative flex-1">
            <OutletSlot />
          </div>
        </div>
        <DownloadModal
          type="resume"
          loading={loading}
          isOpen={openDownloadPdf}
          startDownload={onPrint}
          isPremium={true}
          onOpenChange={handleDownloadPdf}
        />
        <TemplateDialog />
        {/* <CreatePreviewDEV /> */}
      </div>
    );
  }

  return (
    <div className="fixed w-full flex-col">
      {/* <header className="z-[100] flex h-16 gap-4 border-b bg-background px-4 md:px-6">
        <BuilderNavigationMenu />
      </header> */}
      <div className="z-[100]">
        <ProjectInfo
          projectName={title}
          activeTab={currentBuilderTab}
          onTabChange={handleTabChange}
          onProjectNameChange={onTitleUpdate}
        />
      </div>

      <Sheet open={sheet.right.open} onOpenChange={sheet.right.setOpen}>
        <SheetContent side="right" className="p-0" onOpenAutoFocus={onOpenAutoFocus}>
          <OutletSlot />
        </SheetContent>
      </Sheet>
      <LeftSidebar />
      <Sheet open={sheet.bottom.open} onOpenChange={sheet.bottom.setOpen}>
        <SheetContent side="bottom" className="h-1/2 p-0" onOpenAutoFocus={onOpenAutoFocus}>
          <RightSidebar />
        </SheetContent>
      </Sheet>
      <DownloadModal
        type="resume"
        loading={loading}
        isOpen={openDownloadPdf}
        startDownload={onPrint}
        isPremium={true}
        onOpenChange={handleDownloadPdf}
      />
    </div>
  );
};
