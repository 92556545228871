import { CoverLetterDto, DeleteCoverLetterDto } from "@resume-plus/dto";
import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { axios } from "@/client/libs/axios";
import { queryClient } from "@/client/libs/query-client";

export const deleteCover = async (data: DeleteCoverLetterDto) => {
  const response = await axios.delete<
    CoverLetterDto,
    AxiosResponse<CoverLetterDto>,
    DeleteCoverLetterDto
  >(`/cover/${data.id}`);

  return response.data;
};

export const useDeleteCover = () => {
  const {
    error,
    isPending: loading,
    mutateAsync: deleteCoverFn,
  } = useMutation({
    mutationFn: deleteCover,
    onSuccess: (data) => {
      queryClient.removeQueries({ queryKey: ["cover", data.id] });

      queryClient.setQueryData<CoverLetterDto[]>(["covers"], (cache) => {
        if (!cache) return [];
        return cache.filter((cover) => cover.id !== data.id);
      });
    },
  });

  return { deleteCover: deleteCoverFn, loading, error };
};
