import { resumeDataSchema } from "@resume-plus/schema";
import { z } from "zod";

export const documentSchema = z.object({
  id: z.number().optional(),
  title: z.string().min(1),
  category: z.string().optional(),
  description: z.string().optional(),
  tags: z.array(z.string()).optional(),
  thumbnailUrl: z.string().min(1),
  config: resumeDataSchema,
});

export type ResumeExample = z.infer<typeof documentSchema>;
