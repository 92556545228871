import type React from "react";

import { Tooltip as ToolTipComponent, TooltipContent, TooltipTrigger } from "./tooltip";

type TooltipType = {
  children: React.ReactNode;
  content: React.ReactNode;
  side?: "right" | "top" | "bottom" | "left" | undefined;
};

export const TooltipWrapper: React.FC<TooltipType> = ({ children, content, side }) => (
  <ToolTipComponent>
    <TooltipTrigger asChild>{children}</TooltipTrigger>
    <TooltipContent side={side}>
      <p>{content}</p>
    </TooltipContent>
  </ToolTipComponent>
);
