/* eslint-disable lingui/no-unlocalized-strings */
// eslint-disable-next-line lingui/no-unlocalized-strings
"use client";

import { cn } from "@resume-plus/utils";
import { ReactNode, useEffect, useState } from "react";

type BlinkWrapperProps = {
  children: ReactNode;
  className?: string;
  duration?: number;
  blink: boolean;
  onBlinkComplete?: () => void;
};

export function BlinkWrapper({
  children,
  className,
  duration = 500,
  blink,
  onBlinkComplete,
}: BlinkWrapperProps) {
  const [isBlinking, setIsBlinking] = useState(false);
  const [blinkCount, setBlinkCount] = useState(0);

  useEffect(() => {
    if (isBlinking) {
      const blinkInterval = setInterval(() => {
        setBlinkCount((prevCount) => {
          if (prevCount >= 2) {
            clearInterval(blinkInterval);
            setIsBlinking(false);
            if (onBlinkComplete) onBlinkComplete();
            return 0;
          }
          return prevCount + 1;
        });
      }, duration);

      return () => {
        clearInterval(blinkInterval);
      };
    }
  }, [isBlinking, duration, onBlinkComplete]);

  const triggerBlink = () => {
    setIsBlinking(true);
    setBlinkCount(0);
  };

  useEffect(() => {
    if (blink) triggerBlink();
  }, [blink]);

  return (
    <span
      className={cn(
        "relative",
        "transition-all duration-500",
        isBlinking && blinkCount % 2 === 1 && ["bg-primary/5", "ring-1 ring-primary/50"],
        className,
      )}
    >
      {children}
    </span>
  );
}
