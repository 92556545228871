/* eslint-disable lingui/no-unlocalized-strings */
import { Dialog, DialogContent, DialogHeader, DialogTitle, ScrollArea } from "@resume-plus/ui";
import { templatesList } from "@resume-plus/utils";
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useRef } from "react";

import { usePreviewStore } from "@/client/stores/preview";
import { useResumeStore } from "@/client/stores/resume";

export const TemplateDialog = () => {
  const setValue = useResumeStore((state) => state.setValue);
  const currentTemplate = useResumeStore((state) => state.document.data.metadata.template);
  const openTemplateView = usePreviewStore((state) => state.openTemplateView);
  const handleSelectTemplate = usePreviewStore((state) => state.handleSelectTemplate);

  const [isMounted, setIsMounted] = React.useState(false);
  const selectedTemplateRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      selectedTemplateRef.current?.scrollIntoView({
        behavior: "instant",
        block: "center",
      });
    });
  }, [openTemplateView, selectedTemplateRef.current]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.1,
        when: "beforeChildren",
        staggerChildren: 0.05,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.3,
      },
    },
  };

  const templateItems = React.useMemo(
    () =>
      templatesList.map((template) => (
        <motion.div
          key={template}
          ref={template === currentTemplate ? selectedTemplateRef : null}
          variants={itemVariants}
          className="m-2"
        >
          <div
            className={`relative cursor-pointer rounded-sm shadow-2xl ring-2 transition-all duration-200 ${
              currentTemplate === template ? "ring-primary" : "ring-secondary"
            }`}
            onClick={() => {
              setValue("metadata.template", template);
            }}
          >
            <img
              src={`/templates/jpg/${template}.jpg`}
              alt={template}
              className="w-full rounded-lg object-cover"
              loading="lazy"
              width={300}
              height={400}
            />
          </div>
        </motion.div>
      )),
    [currentTemplate, setValue],
  );

  if (!isMounted) return null;

  return (
    <Dialog open={openTemplateView} onOpenChange={handleSelectTemplate}>
      <DialogContent className="z-[200] max-h-[80vh] max-w-[60vw]">
        <DialogHeader>
          <DialogTitle>All Templates</DialogTitle>
        </DialogHeader>

        <ScrollArea className="relative h-[calc(80vh-8rem)] w-full">
          <AnimatePresence mode="wait">
            {openTemplateView && (
              <motion.div
                variants={containerVariants}
                initial="hidden"
                animate="visible"
                exit="hidden"
                className="grid grid-cols-3 gap-4 p-4"
              >
                {templateItems}
              </motion.div>
            )}
          </AnimatePresence>
        </ScrollArea>
      </DialogContent>
    </Dialog>
  );
};
