import { z } from "zod";

export const defaultCoverLetterContent = {
  recipient: "",
  company: "",
  jobTitle: "",
  letterBody: "",
};

export const coverLetterContentSchema = z.object({
  recipient: z.string(),
  company: z.string(),
  jobTitle: z.string(),
  letterBody: z.string(),
});
