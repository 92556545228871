import { motion } from "framer-motion";
import { CirclePlus, Info, Plus } from "lucide-react";

import { useDialog } from "@/client/stores/dialog";
import { useResumeSampleStore } from "@/client/stores/sample-resumes";

import { NewResumeOption } from "./new-resume-option";
import { cardVariants, contentVariants } from "./utils";

export const NewResumeCard = () => {
  const { open } = useDialog("resume");
  const setOpenTemplateDialog = useResumeSampleStore((state) => state.setOpenTemplateDialog);

  return (
    <motion.div
      className="flex flex-col gap-4 rounded-lg bg-[#F4F3FF] p-4 sm:h-[372px] sm:flex-row sm:gap-6 sm:p-6"
      variants={cardVariants}
      initial="hidden"
      animate="visible"
    >
      <motion.div
        className="flex w-full items-center justify-center rounded-lg bg-[#F5FBF7] p-6 sm:w-[400px]"
        whileHover={{ scale: 1.1 }}
        transition={{ type: "spring", stiffness: 400, damping: 17 }}
        onClick={() => {
          open("create");
        }}
      >
        <div className="flex size-12 items-center justify-center rounded-full bg-success">
          <Plus className="size-6 text-white" />
        </div>
      </motion.div>

      <motion.div className="flex grow flex-col" variants={contentVariants}>
        <div className="mb-4 flex items-start gap-2 rounded-lg bg-white p-3">
          <Info className="mt-0.5 size-4 shrink-0 text-info" />
          <p className="text-sm text-gray-600">
            Did you know? Tailoring your resume to the job description can double your chances of
            getting an interview.
          </p>
        </div>

        <div className="space-y-3">
          <div className="space-y-4">
            <NewResumeOption
              icon={<CirclePlus className="size-4 text-[#12B76A]" />}
              title="Create new resume"
              description="Start from scratch and build your resume step by step"
              onClick={() => {
                open("create");
              }}
            />
            <NewResumeOption
              icon={<CirclePlus className="size-4 text-[#12B76A]" />}
              title="Create resume from sample"
              description="Choose from our professionally designed templates to get started quickly"
              onClick={() => {
                setOpenTemplateDialog(true);
              }}
            />
            {/* <NewResumeOption
              icon={<CirclePlus className="size-4 text-[#12B76A]" />}
              title="Import existing resume"
              description="Easily upload your current resume to enhance and update it with our editing tools"
            /> */}
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};
