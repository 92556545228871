/* eslint-disable lingui/no-unlocalized-strings */
import { t } from "@lingui/macro";
import { RichInput } from "@resume-plus/ui";

import { AiActions } from "@/client/components/ai-actions";
import { useCoverLetterStore } from "@/client/stores/resume";

import { getSectionIcon } from "./shared/section-icon";

export const SummarySection = () => {
  const setValue = useCoverLetterStore((state) => state.setValue);
  const letterBody = useCoverLetterStore(
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    (state) => state.document.data.content.letterBody,
  );

  return (
    <section id="summary" className="grid gap-y-2">
      <header className="flex items-center justify-between">
        <div className="flex items-center gap-x-2">
          {getSectionIcon("summary")}
          <h2 className="line-clamp-1 text-xl font-semibold font-inter">Letter Details</h2>
        </div>
      </header>

      <main>
        <p className="text-xs leading-relaxed opacity-75 mb-1">
          {t`Write 3-4 paragraphs detailing why you are the ideal candidate for the specific job.`}
        </p>
        <RichInput
          className="!h-4/5 !rounded-none"
          placeholder="Cover letter contents..."
          editorClassName="!h-full"
          content={letterBody}
          footer={(editor) => (
            <AiActions value={editor.getText()} onChange={editor.commands.setContent} />
          )}
          onChange={(value) => {
            setValue("content.letterBody", value);
          }}
        />
      </main>
    </section>
  );
};
