import { ResumeDto, ResumeExample, UserDto } from "@resume-plus/dto";
import { generateRandomName, kebabCase } from "@resume-plus/utils";
import { create } from "zustand";

import { importResumeMutation } from "../services/resume/import";

type ResumeSampleStore = {
  documents: ResumeExample[];
  setDocuments: (documents: ResumeExample[]) => void;
  addDocument: (document: ResumeExample) => void;
  updateDocument: (id: number, updatedDocument: Partial<ResumeExample>) => void;
  removeDocument: (id: number) => void;

  openTemlateDialog: boolean;
  setOpenTemplateDialog: (open: boolean) => void;

  onTemplateSelected: (
    document: ResumeExample | null,
    user: UserDto | undefined,
    randomNum: number,
  ) => Promise<ResumeDto | null>;
};

export const useResumeSampleStore = create<ResumeSampleStore>((set) => ({
  documents: [],

  setDocuments: (documents) => {
    set(() => ({ documents }));
  },

  addDocument: (document) => {
    set((state) => ({
      documents: [...state.documents, document],
    }));
  },

  updateDocument: (id, updatedDocument) => {
    set((state) => ({
      documents: state.documents.map((doc) =>
        doc.id === id ? { ...doc, ...updatedDocument } : doc,
      ),
    }));
  },

  removeDocument: (id) => {
    set((state) => ({
      documents: state.documents.filter((doc) => doc.id !== id),
    }));
  },

  openTemlateDialog: false,
  setOpenTemplateDialog: (open) => {
    set(() => ({ openTemlateDialog: open }));
  },

  onTemplateSelected: async (
    document: ResumeExample | null,
    user: UserDto | undefined,
    randomNum: number,
  ) => {
    const color = generateRandomName();
    const title = document?.title;
    const slug = kebabCase(`${title} ${color} ${randomNum}`);
    const data = document?.config;

    if (!data) return null;

    data.basics.name = user?.name ?? data.basics.name;
    data.basics.email = user?.email ?? data.basics.email;
    data.basics.picture.url = user?.picture ?? data.basics.picture.url;

    return await importResumeMutation({
      title: title,
      slug: slug,
      data: data,
    });
  },
}));
