import { t } from "@lingui/macro";
import { ScrollArea, Separator } from "@resume-plus/ui";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet-async";

import { AppHeader } from "../../dashboard-new/_components/dashboard-header";
import { AccountSettings } from "./_sections/account";
import { DangerZoneSettings } from "./_sections/danger";
import { OpenAISettings } from "./_sections/openai";
import { ProfileSettings } from "./_sections/profile";
import { SecuritySettings } from "./_sections/security";

export const SettingsPage = () => (
  <>
    <Helmet>
      <title>
        {t`Settings`} - {t`Resume Studio`}
      </title>
    </Helmet>
    <div className="min-h-screen bg-background">
      <AppHeader />
      <div className="container mx-auto space-y-6 p-4 sm:p-6">
        <motion.h1
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          className="text-4xl font-bold tracking-tight"
        >
          {t`Settings`}
        </motion.h1>

        <ScrollArea hideScrollbar className="h-[calc(100vh-140px)] lg:h-[calc(100vh-88px)]">
          <div className="space-y-6">
            <AccountSettings />
            <Separator />
            <SecuritySettings />
            <Separator />
            <ProfileSettings />
            <Separator />
            <OpenAISettings />
            <Separator />
            <DangerZoneSettings />
          </div>
        </ScrollArea>
      </div>
    </div>
  </>
);
