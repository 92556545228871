/* eslint-disable lingui/no-unlocalized-strings */
import { t } from "@lingui/macro";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@resume-plus/ui";
import { Switch, Label } from "@resume-plus/ui";
import { PictureOptionsProps } from "@resume-plus/utils";
import React, { useMemo } from "react";

// Size Options
const sizeOptions = [
  { value: "100", label: "Small" },
  { value: "128", label: "Medium" },
  { value: "200", label: "Large" },
  { value: "250", label: "Extra Large" },
] as const;

// Aspect Ratio Helpers
const aspectRatioOptions = [
  { value: "1", label: "Square (1:1)", ratio: 1 },
  { value: "0.75", label: "Portrait (3:4)", ratio: 0.75 },
  { value: "1.33", label: "Horizontal (4:3)", ratio: 1.33 },
] as const;

// Border Radius Helpers
const borderRadiusOptions = [
  { value: "0", label: "Square", radius: 0 },
  { value: "6", label: "Rounded", radius: 6 },
  { value: "9999", label: "Circle", radius: 9999 },
] as const;

type PictureOptionsKeys =
  | keyof PictureOptionsProps
  | `effects.${keyof PictureOptionsProps["effects"]}`;

type PictureOptionsComponentProps = {
  picture: PictureOptionsProps;
  setValue: (key: PictureOptionsKeys, value: string | number | boolean) => void;
};

export const PictureOptions: React.FC<PictureOptionsComponentProps> = ({ picture, setValue }) => {
  const currentSize = useMemo(() => {
    return picture.size.toString();
  }, [picture.size]);

  const currentAspectRatio = useMemo(() => {
    return picture.aspectRatio.toString();
  }, [picture.aspectRatio]);

  const currentBorderRadius = useMemo(() => {
    return picture.borderRadius?.toString() ?? "0";
  }, [picture.borderRadius]);

  return (
    <div className="flex flex-col gap-y-5">
      <div className="grid grid-cols-3 items-center gap-x-6">
        <Label htmlFor="picture.size">{t`Size`}</Label>
        <Select
          value={currentSize}
          onValueChange={(value) => {
            setValue("size", Number.parseInt(value));
          }}
        >
          <SelectTrigger className="col-span-2">
            <SelectValue placeholder="Select size" />
          </SelectTrigger>
          <SelectContent className="z-[201]">
            {sizeOptions.map((option) => (
              <SelectItem key={option.value} value={option.value}>
                {option.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      <div className="grid grid-cols-3 items-center gap-x-6">
        <Label htmlFor="picture.aspectRatio">{t`Aspect Ratio`}</Label>
        <Select
          value={currentAspectRatio}
          onValueChange={(value) => {
            const option = aspectRatioOptions.find((opt) => opt.value === value);
            if (option) {
              setValue("aspectRatio", option.ratio);
            }
          }}
        >
          <SelectTrigger className="col-span-2">
            <SelectValue placeholder="Select aspect ratio" />
          </SelectTrigger>
          <SelectContent className="z-[201]">
            {aspectRatioOptions.map((option) => (
              <SelectItem key={option.value} value={option.value}>
                {option.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      <div className="grid grid-cols-3 items-center gap-x-6">
        <Label htmlFor="picture.borderRadius">{t`Border Radius`}</Label>
        <Select
          value={currentBorderRadius}
          onValueChange={(value) => {
            const option = borderRadiusOptions.find((opt) => opt.value === value);
            if (option) {
              setValue("borderRadius", option.radius);
            }
          }}
        >
          <SelectTrigger className="col-span-2">
            <SelectValue placeholder="Select border radius" />
          </SelectTrigger>
          <SelectContent className="z-[201]">
            {borderRadiusOptions.map((option) => (
              <SelectItem key={option.value} value={option.value}>
                {option.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      <div>
        <div className="grid grid-cols-3 items-start gap-x-6">
          <div>
            <Label>{t`Effects`}</Label>
          </div>
          <div className="col-span-2 space-y-4">
            <div className="flex items-center space-x-2">
              <Switch
                id="picture.effects.hidden"
                checked={picture.effects.hidden}
                onCheckedChange={(checked) => {
                  setValue("effects.hidden", checked);
                }}
              />
              <Label htmlFor="picture.effects.hidden">{t`Hidden`}</Label>
            </div>

            <div className="flex items-center space-x-2">
              <Switch
                id="picture.effects.border"
                checked={picture.effects.border}
                onCheckedChange={(checked) => {
                  setValue("effects.border", checked);
                }}
              />
              <Label htmlFor="picture.effects.border">{t`Border`}</Label>
            </div>

            <div className="flex items-center space-x-2">
              <Switch
                id="picture.effects.grayscale"
                checked={picture.effects.grayscale}
                onCheckedChange={(checked) => {
                  setValue("effects.grayscale", checked);
                }}
              />
              <Label htmlFor="picture.effects.grayscale">{t`Grayscale`}</Label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
