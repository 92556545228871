import { BuilderNavigationMenu } from "../../builder/_components/header";
import { UserMenu } from "./user-menu";

export const AppHeader = () => {
  return (
    <header className="z-[200] flex h-16 gap-4 border-b-2 border-border bg-background px-4 md:px-6">
      <BuilderNavigationMenu />
      <div className="hidden w-full items-center justify-end gap-4 md:ml-auto md:flex md:gap-2 lg:gap-4">
        <UserMenu />
      </div>
    </header>
  );
};
