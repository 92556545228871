import { ResumeDto } from "@resume-plus/dto";
import dayjs from "dayjs";
import { AnimatePresence, motion } from "framer-motion";
import { Clock, Copy, Download, Edit3, Loader2, Trash2 } from "lucide-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useResumePreview } from "@/client/services/resume/preview";
import { useDialog } from "@/client/stores/dialog";
import { usePreviewStore } from "@/client/stores/preview";

import { buttonVariants, cardVariants, contentVariants } from "./utils";

export type ResumeCardProps = {
  resume: ResumeDto;
  resumeNumber: number;
  setLastId: (id: string) => void;
};

export const ResumeCard = ({ resume, resumeNumber, setLastId }: ResumeCardProps) => {
  const { title } = resume;
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();
  const { open } = useDialog<ResumeDto>("resume");
  const { url, loading } = useResumePreview(resume.id);
  const lastUpdated = dayjs().to(resume.updatedAt);
  const createdAtString = dayjs(resume.createdAt).format("MMM D, YYYY");
  const handleDownloadPdf = usePreviewStore((state) => state.handleOpenDownloadPdf);

  const onOpen = () => {
    navigate(`/builder/resume/${resume.id}`);
  };

  const onDuplicate = () => {
    open("duplicate", { id: "resume", item: resume });
  };

  const onDelete = () => {
    open("delete", { id: "resume", item: resume });
  };

  return (
    <motion.div
      className="flex flex-col rounded-lg bg-[#F4F3FF] p-4 transition-shadow duration-300 sm:h-[372px] sm:flex-row sm:gap-6 sm:p-6"
      variants={cardVariants}
      initial="hidden"
      animate="visible"
      style={{
        boxShadow: isHovered
          ? "0 4px 20px rgba(115, 99, 243, 0.1)"
          : "0 2px 10px rgba(115, 99, 243, 0.05)",
      }}
      onHoverStart={() => {
        setIsHovered(true);
      }}
      onHoverEnd={() => {
        setIsHovered(false);
      }}
    >
      {/* Preview Image Container */}
      <motion.div
        className="relative aspect-[3/4] w-full cursor-pointer overflow-hidden rounded border border-gray-200 bg-white sm:aspect-[3/4] sm:w-[229px] sm:shrink-0"
        whileHover={{ scale: 1.07 }}
        transition={{ type: "spring", stiffness: 300, damping: 20 }}
        onClick={onOpen}
      >
        {loading ? (
          <div className="flex size-full items-center justify-center">
            <Loader2 className="size-8 animate-spin text-gray-400" />
          </div>
        ) : (
          <img
            loading="lazy"
            src={url}
            alt={`${title} preview`}
            className="absolute inset-0 size-full object-contain transition-transform duration-300"
          />
        )}
      </motion.div>

      {/* Content Container */}
      <motion.div className="flex min-w-0 flex-1 flex-col pt-4 sm:pt-0" variants={contentVariants}>
        {/* Header Section */}
        <div className="mb-4 flex min-w-0 items-start justify-between">
          <div className="min-w-0 flex-1">
            <motion.div
              className="mb-1 font-medium text-[#7363F3]"
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3, duration: 0.3 }}
            >
              RESUME #{resumeNumber}
            </motion.div>
            <div className="flex min-w-0 items-center gap-2">
              <h3 className="flex-1 truncate text-lg font-medium">{title}</h3>
            </div>
          </div>
        </div>

        <div className="mb-4 text-sm text-gray-500">Edited {lastUpdated}</div>

        {/* Actions Section */}
        <div className="flex-1 space-y-1">
          <motion.button
            className="flex w-full items-center gap-2 rounded-md p-2 text-sm text-gray-600 transition-colors duration-200 hover:bg-white/50 hover:text-gray-900"
            variants={buttonVariants}
            whileHover="hover"
            onClick={onOpen}
          >
            <AnimatePresence>
              <motion.span
                initial={{ opacity: 0, x: -10 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -10 }}
                transition={{ delay: 0, duration: 0.2 }}
                className="shrink-0"
              >
                <Edit3 className="size-4 text-gray-400" />
              </motion.span>
            </AnimatePresence>
            <span className="truncate">Edit</span>
          </motion.button>

          <motion.button
            className="flex w-full items-center gap-2 rounded-md p-2 text-sm text-gray-600 transition-colors duration-200 hover:bg-white/50 hover:text-gray-900"
            variants={buttonVariants}
            whileHover="hover"
            onClick={onDuplicate}
          >
            <AnimatePresence>
              <motion.span
                initial={{ opacity: 0, x: -10 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -10 }}
                transition={{ delay: 0.1, duration: 0.2 }}
                className="shrink-0"
              >
                <Copy className="size-4 text-gray-400" />
              </motion.span>
            </AnimatePresence>
            <span className="truncate">Duplicate</span>
          </motion.button>

          <motion.button
            className="flex w-full items-center gap-2 rounded-md p-2 text-sm text-gray-600 transition-colors duration-200 hover:bg-white/50 hover:text-gray-900"
            variants={buttonVariants}
            whileHover="hover"
            onClick={() => {
              setLastId(resume.id);
              handleDownloadPdf(true);
            }}
          >
            <AnimatePresence>
              <motion.span
                initial={{ opacity: 0, x: -10 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -10 }}
                transition={{ delay: 0.2, duration: 0.2 }}
                className="shrink-0"
              >
                <Download className="size-4 text-gray-400" />
              </motion.span>
            </AnimatePresence>
            <span className="truncate">Download</span>
          </motion.button>

          <motion.button
            className="flex w-full items-center gap-2 rounded-md p-2 text-sm text-error transition-colors duration-200 hover:bg-white/50 hover:text-red-700"
            variants={buttonVariants}
            whileHover="hover"
            onClick={onDelete}
          >
            <AnimatePresence>
              <motion.span
                initial={{ opacity: 0, x: -10 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -10 }}
                transition={{ delay: 0.3, duration: 0.2 }}
                className="shrink-0"
              >
                <Trash2 className="size-4" />
              </motion.span>
            </AnimatePresence>
            <span className="truncate">Delete</span>
          </motion.button>
        </div>

        {/* Footer Section */}
        <motion.div
          className="mt-4 flex items-center justify-between border-t border-gray-200/50 pt-4 text-sm text-gray-500"
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5, duration: 0.3 }}
        >
          <div className="flex min-w-0 items-center gap-2">
            <Clock className="size-4 shrink-0" />
            <span className="truncate">Created on {createdAtString}</span>
          </div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default ResumeCard;
