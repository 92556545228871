import { UrlDto } from "@resume-plus/dto";
import { useMutation, useQuery } from "@tanstack/react-query";

import { RESUME_PREVIEW_KEY } from "@/client/constants/query-keys";
import { toast } from "@/client/hooks/use-toast";
import { axios } from "@/client/libs/axios";

export const previewResume = async (data: { id: string; force?: boolean }) => {
  const response = await axios.get<UrlDto>(`/resume/print/${data.id}/preview?force=${data.force}`);

  return response.data;
};

export const useResumePreview = (id: string) => {
  const {
    error,
    isPending: loading,
    data,
  } = useQuery({
    queryKey: [RESUME_PREVIEW_KEY, { id }],
    queryFn: () => previewResume({ id }),
  });

  return { url: data?.url, loading, error };
};

export const useResumePreviewAsync = () => {
  const {
    error,
    isPending: loading,
    mutateAsync: previewResumeFn,
  } = useMutation({
    mutationFn: previewResume,
    onError: (error) => {
      const message = error.message;

      toast({
        variant: "error",
        title: `Oops, the server returned an error.`,
        description: message,
      });
    },
  });

  return { previewResume: previewResumeFn, loading, error };
};
