import { z } from "zod";

import { coverLetterBasicsSchema, defaultCoverLetterHeader } from "./basics";
import { coverLetterContentSchema, defaultCoverLetterContent } from "./content";
import { coverLetterMetadataSchema, defaultCoverLetterMetadata } from "./metadata";

export const coverLetterDataSchema = z.object({
  basics: coverLetterBasicsSchema,
  content: coverLetterContentSchema,
  metadata: coverLetterMetadataSchema,
});

export const defaultCoverLetterData: CoverLetterData = {
  basics: defaultCoverLetterHeader,
  content: defaultCoverLetterContent,
  metadata: defaultCoverLetterMetadata,
};

export type CoverLetterData = z.infer<typeof coverLetterDataSchema>;

export * from "./basics";
export * from "./content";
export * from "./metadata";
