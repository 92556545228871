/* eslint-disable tailwindcss/no-custom-classname */
import { t } from "@lingui/macro";
import { Plus, PlusCircle } from "@phosphor-icons/react";
import type {
  Award,
  Certification,
  CustomSection,
  Education,
  Experience,
  Interest,
  Language,
  Profile,
  Project,
  Publication,
  Reference,
  Skill,
  Volunteer,
} from "@resume-plus/schema";
import { Button, ScrollArea, Separator } from "@resume-plus/ui";
import React, { Fragment, useEffect, useRef, useState } from "react";

import { BlinkWrapper } from "@/client/hooks/use-blink";
import { useResumeStore } from "@/client/stores/resume";

import { BasicsSection } from "./sections/basics";
import { SectionBase } from "./sections/shared/section-base";
import { SectionIcon } from "./sections/shared/section-icon";
import { SummarySection } from "./sections/summary";

type LeftSidebarProps = {
  startAtSection?: string;
  onScrollFinished?: (side: "left" | "right", lastSection: string) => void;
};

export const LeftSidebar: React.FC<LeftSidebarProps> = ({ startAtSection, onScrollFinished }) => {
  const containterRef = useRef<HTMLDivElement | null>(null);

  const addSection = useResumeStore((state) => state.addSection);
  const customSections = useResumeStore((state) => state.document.data.sections.custom);

  const [highlightedId, setHighlightedId] = useState<string | null>(null);
  const scrollIntoView = (selector: string) => {
    const section = containterRef.current?.querySelector(selector);
    section?.scrollIntoView({ behavior: "smooth" });

    setHighlightedId(selector);

    onScrollFinished?.("left", selector);
  };

  const handleMessage = (event: MessageEvent) => {
    switch (event.data.type) {
      case "SECTION_SELECTED": {
        scrollIntoView(`#${event.data.payload.sectionId}`);
        break;
      }
      case "SELECT_TEMPLATE": {
        scrollIntoView("#template");
        break;
      }
    }
  };

  useEffect(() => {
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [handleMessage]);

  useEffect(() => {
    if (startAtSection) {
      scrollIntoView(`#${startAtSection}`);
    }
  }, [startAtSection]);

  return (
    <div className="flex bg-secondary-accent/30">
      <div className="hidden basis-12 flex-col items-center justify-center gap-y-4 bg-secondary-accent/30 py-4 sm:flex">
        <SectionIcon
          id="basics"
          name={t({
            message: "Basics",
            context:
              "The basics section of a resume consists of User's Picture, Full Name, Location etc.",
          })}
          onClick={() => {
            scrollIntoView("#basics");
          }}
        />
        <SectionIcon
          id="summary"
          onClick={() => {
            scrollIntoView("#summary");
          }}
        />
        <SectionIcon
          id="profiles"
          onClick={() => {
            scrollIntoView("#profiles");
          }}
        />
        <SectionIcon
          id="experience"
          onClick={() => {
            scrollIntoView("#experience");
          }}
        />
        <SectionIcon
          id="education"
          onClick={() => {
            scrollIntoView("#education");
          }}
        />
        <SectionIcon
          id="skills"
          onClick={() => {
            scrollIntoView("#skills");
          }}
        />
        <SectionIcon
          id="languages"
          onClick={() => {
            scrollIntoView("#languages");
          }}
        />
        <SectionIcon
          id="awards"
          onClick={() => {
            scrollIntoView("#awards");
          }}
        />
        <SectionIcon
          id="certifications"
          onClick={() => {
            scrollIntoView("#certifications");
          }}
        />
        <SectionIcon
          id="interests"
          onClick={() => {
            scrollIntoView("#interests");
          }}
        />
        <SectionIcon
          id="projects"
          onClick={() => {
            scrollIntoView("#projects");
          }}
        />
        <SectionIcon
          id="publications"
          onClick={() => {
            scrollIntoView("#publications");
          }}
        />
        <SectionIcon
          id="volunteer"
          onClick={() => {
            scrollIntoView("#volunteer");
          }}
        />
        <SectionIcon
          id="references"
          onClick={() => {
            scrollIntoView("#references");
          }}
        />

        <SectionIcon
          id="custom"
          variant="outline"
          name={t`Add a new section`}
          icon={<Plus size={14} />}
          onClick={() => {
            addSection?.();
            // eslint-disable-next-line lingui/no-unlocalized-strings
            scrollIntoView("& > section:last-of-type");
          }}
        />

        {/* <UserOptions>
          <Button size="icon" variant="ghost" className="rounded-full">
            <UserAvatar size={28} />
          </Button>
        </UserOptions> */}
      </div>

      <ScrollArea orientation="vertical" className="h-[calc(100vh-110px)] flex-1">
        <div ref={containterRef} className="grid gap-y-6 p-6 @container/left">
          <BlinkWrapper blink={highlightedId === `#basics`}>
            <BasicsSection />
          </BlinkWrapper>
          <Separator />

          <BlinkWrapper blink={highlightedId === `#summary`}>
            <SummarySection />
          </BlinkWrapper>
          <Separator />

          <BlinkWrapper blink={highlightedId === `#profiles`}>
            <SectionBase<Profile>
              id="profiles"
              title={(item) => item.network}
              description={(item) => item.username}
            />
          </BlinkWrapper>
          <Separator />

          <BlinkWrapper blink={highlightedId === `#experience`}>
            <SectionBase<Experience>
              id="experience"
              title={(item) => item.company}
              description={(item) => item.position}
            />
          </BlinkWrapper>
          <Separator />

          {/* Repeat this pattern for other sections */}
          <BlinkWrapper blink={highlightedId === `#education`}>
            <SectionBase<Education>
              id="education"
              title={(item) => item.institution}
              description={(item) => item.area}
            />
          </BlinkWrapper>
          <Separator />

          <BlinkWrapper blink={highlightedId === `#skills`}>
            <SectionBase<Skill>
              id="skills"
              title={(item) => item.name}
              description={(item) => {
                if (item.description) return item.description;
                if (item.keywords.length > 0) return `${item.keywords.length} keywords`;
              }}
            />
          </BlinkWrapper>
          <Separator />

          <BlinkWrapper blink={highlightedId === `#languages`}>
            <SectionBase<Language>
              id="languages"
              title={(item) => item.name}
              description={(item) => item.description}
            />
          </BlinkWrapper>
          <Separator />

          <BlinkWrapper blink={highlightedId === `#awards`}>
            <SectionBase<Award>
              id="awards"
              title={(item) => item.title}
              description={(item) => item.awarder}
            />
          </BlinkWrapper>
          <Separator />

          <BlinkWrapper blink={highlightedId === `#certifications`}>
            <SectionBase<Certification>
              id="certifications"
              title={(item) => item.name}
              description={(item) => item.issuer}
            />
          </BlinkWrapper>
          <Separator />

          <BlinkWrapper blink={highlightedId === `#interests`}>
            <SectionBase<Interest>
              id="interests"
              title={(item) => item.name}
              description={(item) => {
                if (item.keywords.length > 0) return `${item.keywords.length} keywords`;
              }}
            />
          </BlinkWrapper>
          <Separator />

          <BlinkWrapper blink={highlightedId === `#projects`}>
            <SectionBase<Project>
              id="projects"
              title={(item) => item.name}
              description={(item) => item.description}
            />
          </BlinkWrapper>
          <Separator />

          <BlinkWrapper blink={highlightedId === `#publications`}>
            <SectionBase<Publication>
              id="publications"
              title={(item) => item.name}
              description={(item) => item.publisher}
            />
          </BlinkWrapper>
          <Separator />

          <BlinkWrapper blink={highlightedId === `#volunteer`}>
            <SectionBase<Volunteer>
              id="volunteer"
              title={(item) => item.organization}
              description={(item) => item.position}
            />
          </BlinkWrapper>
          <Separator />

          <BlinkWrapper blink={highlightedId === `#references`}>
            <SectionBase<Reference>
              id="references"
              title={(item) => item.name}
              description={(item) => item.description}
            />
          </BlinkWrapper>
          <Separator />

          {Object.values(customSections).map((section) => (
            <Fragment key={section.id}>
              <Separator />
              <BlinkWrapper blink={highlightedId === `#custom.${section.id}`}>
                <SectionBase<CustomSection>
                  id={`custom.${section.id}`}
                  title={(item) => item.name}
                  description={(item) => item.description}
                />
              </BlinkWrapper>
            </Fragment>
          ))}

          <Separator />

          <Button size="lg" variant="outline" onClick={addSection}>
            <PlusCircle />
            <span className="ml-2">{t`Add a new section`}</span>
          </Button>
        </div>
      </ScrollArea>
    </div>
  );
};
