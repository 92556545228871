import { ResumeExample } from "@resume-plus/dto";
import {
  Dialog,
  DialogContent,
  Progress,
  ResponsiveDialog,
  ResponsiveDialogContent,
  ResumeSamples,
  ScrollArea,
} from "@resume-plus/ui";
import { Loader2 } from "lucide-react";
import { Suspense, useCallback, useEffect, useRef, useState } from "react";
import { Await, defer, useLoaderData, useLocation, useNavigate } from "react-router-dom";

import { queryClient } from "@/client/libs/query-client";
import { useResumes } from "@/client/services/resume";
import { fetchResumeSamples } from "@/client/services/resume/sample-resumes";
import { useUser } from "@/client/services/user";
import { useResumeSampleStore } from "@/client/stores/sample-resumes";

type CreateDocumentPageProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

export const CreateDocumentComponent: React.FC<CreateDocumentPageProps> = ({ open, setOpen }) => {
  const samples = useResumeSampleStore((state) => state.documents);
  const onTemplateSelectedAsync = useResumeSampleStore((state) => state.onTemplateSelected);
  const { user } = useUser();
  const { resumes } = useResumes();

  const onTemplateSelected = useCallback(
    (template: ResumeExample | null) => {
      void onTemplateSelectedAsync(template, user, resumes?.length ?? 0);
      setOpen(false);
    },
    [setOpen],
  );

  return (
    <ResponsiveDialog open={open} onOpenChange={setOpen}>
      <ResponsiveDialogContent className="h-[calc(90vh-10px)] max-w-6xl rounded-md p-0">
        <ScrollArea>
          <ResumeSamples resumeExamples={samples} onTemplateSelected={onTemplateSelected} />
        </ScrollArea>
      </ResponsiveDialogContent>
    </ResponsiveDialog>
  );
};

const LoadingFallback = () => (
  <Dialog modal open>
    <DialogContent className="sm:max-w-screen-l-xs">
      <div className="flex flex-col items-center justify-center gap-4 p-6">
        <Loader2 className="size-8 animate-spin text-primary" />
        <p className="text-center text-sm text-muted-foreground">Loading templates...</p>
      </div>
    </DialogContent>
  </Dialog>
);

const CreateDocumentContent: React.FC<{ samples: ResumeExample[] }> = ({ samples }) => {
  const { user } = useUser();
  const { resumes } = useResumes();
  const hasProcessedInitialTemplate = useRef(false);
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);
  const [loadingText, setLoadingText] = useState("Preparing template...");

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = Number.parseInt(queryParams.get("id") ?? "-1");
  const onTemplateSelectedAsync = useResumeSampleStore((state) => state.onTemplateSelected);

  useEffect(() => {
    const loadingSteps = [
      { progress: 0, text: "Creating document structure...", delay: 0 },
      { progress: 50, text: "Applying formatting...", delay: 1000 },
      { progress: 90, text: "Finalizing setup...", delay: 2000 },
    ];

    const updateProgress = async (steps: typeof loadingSteps) => {
      for (const step of steps) {
        await new Promise((resolve) => setTimeout(resolve, step.delay));
        setProgress(step.progress);
        setLoadingText(step.text);
      }
    };

    const templateSelectAsync = async () => {
      if (!user) return;
      const selectedSample = samples.find((sample) => sample.id === id);

      if (selectedSample && !hasProcessedInitialTemplate.current) {
        hasProcessedInitialTemplate.current = true;
        await updateProgress(loadingSteps);
        const resume = await onTemplateSelectedAsync(selectedSample, user, resumes?.length ?? 0);

        setProgress(100);
        setLoadingText("Ready! Redirecting...");

        setTimeout(() => {
          if (resume) {
            navigate(`/builder/resume/${resume.id}`);
          }
        }, 500);
      } else if (samples.length > 0 && !selectedSample) {
        navigate("/dashboard");
      }
    };

    void templateSelectAsync();
  }, [id, samples, onTemplateSelectedAsync, user, resumes?.length, navigate]);

  return (
    <Dialog modal open>
      <DialogContent className="sm:max-w-screen-l-xs">
        <div className="flex flex-col items-center justify-center gap-6 p-6">
          <Loader2 className="size-8 animate-spin text-primary" />
          <div className="w-full space-y-4">
            <Progress value={progress} className="w-full" />
            <p className="text-center text-sm text-muted-foreground">{loadingText}</p>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
export const CreateDocumentPage: React.FC = () => {
  const { samples } = useLoaderData() as { samples: Promise<ResumeExample[]> };

  return (
    <Suspense fallback={<LoadingFallback />}>
      <Await resolve={samples}>
        {(resolvedSamples) => <CreateDocumentContent samples={resolvedSamples} />}
      </Await>
    </Suspense>
  );
};

export const createPageLoader = () => {
  const samplesPromise = queryClient
    .fetchQuery({
      queryKey: ["resume-samples"],
      queryFn: () => fetchResumeSamples(),
    })
    .then((samples) => {
      useResumeSampleStore.setState({ documents: samples });
      return samples;
    });

  return defer({
    samples: samplesPromise,
  });
};
