import create from "zustand";

type PreviewStore = {
  currentPage: number;
  totalPages: number;
  setCurrentPage: (page: number) => void;
  setTotalPages: (pages: number) => void;
  handleSelectTemplate: (open: boolean) => void;
  handleOpenDownloadPdf: (open: boolean) => void;
  handlePageChange: (pageNumber: number) => void;
  openTemplateView: boolean;
  openDownloadPdf: boolean;
};

export const usePreviewStore = create<PreviewStore>((set) => ({
  currentPage: 0,
  totalPages: 0,
  openDownloadPdf: false,
  openTemplateView: false,

  setCurrentPage: (page) => {
    set({ currentPage: page });
  },
  setTotalPages: (pages) => {
    set({ totalPages: pages });
  },

  handleSelectTemplate: (open: boolean) => {
    set({ openTemplateView: open });
  },

  handleOpenDownloadPdf: (open) => {
    set({ openDownloadPdf: open });
  },

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handlePageChange: () => {},
}));
