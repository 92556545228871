import { ResumeDto } from "@resume-plus/dto";
import { sortByDate, triggerDownload } from "@resume-plus/utils";
import React, { useEffect, useState } from "react";

import { queryClient } from "@/client/libs/query-client";
import { usePrintResume } from "@/client/services/resume";
import { fetchResumeSamples } from "@/client/services/resume/sample-resumes";
import { usePreviewStore } from "@/client/stores/preview";
import { useResumeSampleStore } from "@/client/stores/sample-resumes";

import DownloadModal from "../../builder/_components/download-dialog";
import { CreateDocumentComponent } from "../../create/page";
import { NewResumeCard } from "./new-resume-card";
import { Pagination } from "./pagination";
import { ResumeCard } from "./resume-card";

type ResumeGridProps = {
  resumes?: ResumeDto[];
  currentPage: number;
  setCurrentPage: (page: number) => void;
  filter?: string;
};

type SearchPath = {
  path: string;
  getter: (obj: ResumeDto) => string | undefined;
};

export const ResumeGrid: React.FC<ResumeGridProps> = ({
  resumes,
  currentPage,
  filter,
  setCurrentPage,
}) => {
  const openTemlateDialog = useResumeSampleStore((state) => state.openTemlateDialog);
  const setOpenTemplateDialog = useResumeSampleStore((state) => state.setOpenTemplateDialog);

  const itemsPerPage = 4;

  useEffect(() => {
    const fetchSamples = async () => {
      const samples = await queryClient.fetchQuery({
        queryKey: ["resume-samples"],
        queryFn: () => fetchResumeSamples(),
      });
      useResumeSampleStore.setState({ documents: samples });
    };
    void fetchSamples();
  }, []);

  const searchableFields: SearchPath[] = [
    {
      path: "title",
      getter: (resume: ResumeDto) => resume.title,
    },
    {
      path: "headline",
      getter: (resume: ResumeDto) => resume.data.basics.headline,
    },
    {
      path: "email",
      getter: (resume: ResumeDto) => resume.data.basics.email,
    },
  ];

  const getCurrentPageItems = () => {
    let filteredResumes = resumes?.sort((a, b) => sortByDate(a, b, "updatedAt"));

    if (filter) {
      const searchTerm = filter.toLowerCase();
      filteredResumes = resumes?.filter((resume) =>
        searchableFields.some(({ getter }) => {
          const fieldValue = getter(resume);
          return fieldValue?.toLowerCase().includes(searchTerm);
        }),
      );
    }

    if (currentPage === 1) {
      return filteredResumes?.slice(0, 3) ?? [];
    }

    const startIndex = 3 + (currentPage - 2) * 4;
    return filteredResumes?.slice(startIndex, startIndex + 4) ?? [];
  };

  const totalFilteredItems = filter
    ? resumes?.filter((resume) =>
        searchableFields.some(({ getter }) =>
          getter(resume)?.toLowerCase().includes(filter.toLowerCase()),
        ),
      ).length
    : resumes?.length ?? 0;

  const totalPages = Math.ceil(((totalFilteredItems as number) + 1) / itemsPerPage);
  const [lastId, setLastId] = useState("");

  const openDownloadPdf = usePreviewStore((state) => state.openDownloadPdf);
  const handleDownloadPdf = usePreviewStore((state) => state.handleOpenDownloadPdf);

  const { printResume, loading } = usePrintResume();

  const onPrint = async () => {
    if (lastId === "") return;

    const { url } = await printResume({ id: lastId });

    triggerDownload(url);
  };

  return (
    <div className="flex flex-col">
      <div className="grid min-h-[768px] grow grid-cols-1 gap-4 sm:gap-6 lg:grid-cols-2">
        {currentPage === 1 && <NewResumeCard />}
        {getCurrentPageItems().map((resume, index: number) => (
          <ResumeCard
            key={resume.id}
            resumeNumber={index + 1}
            resume={resume}
            setLastId={setLastId}
          />
        ))}
        {getCurrentPageItems()?.length < (currentPage === 1 ? 3 : 4) &&
          Array.from({
            length: (currentPage === 1 ? 3 : 4) - (getCurrentPageItems()?.length ?? 0),
          }).map((_, i) => (
            <div key={`placeholder-${i}`} className="bg-transparent sm:h-[372px]" />
          ))}
      </div>
      <div className="mt-auto pt-6">
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage}
        />
      </div>
      <DownloadModal
        type="resume"
        loading={loading}
        isOpen={openDownloadPdf}
        startDownload={onPrint}
        isPremium={true}
        onOpenChange={handleDownloadPdf}
      />
      <CreateDocumentComponent open={openTemlateDialog} setOpen={setOpenTemplateDialog} />
    </div>
  );
};
