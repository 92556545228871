import { CoverLetterDto } from "@resume-plus/dto";
import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { COVERS_KEY } from "@/client/constants/query-keys";
import { axios } from "@/client/libs/axios";

export const fetchCovers = async () => {
  const response = await axios.get<CoverLetterDto[], AxiosResponse<CoverLetterDto[]>>("/cover");

  return response.data;
};

export const useCovers = () => {
  const {
    error,
    isPending: loading,
    data: covers,
  } = useQuery({
    queryKey: COVERS_KEY,
    queryFn: fetchCovers,
  });

  return { covers, loading, error };
};
