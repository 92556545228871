import { CoverLetterDto } from "@resume-plus/dto";
import { sortByDate, triggerDownload } from "@resume-plus/utils";
import React, { useState } from "react";

import { usePrintCover } from "@/client/services/cover";
import { usePreviewStore } from "@/client/stores/preview";

import DownloadModal from "../../builder/_components/download-dialog";
import { CoverCard } from "./cover-card";
import { NewCoverCard } from "./new-cover-letter";
import { Pagination } from "./pagination";

type CoverGridProps = {
  covers?: CoverLetterDto[];
  currentPage: number;
  setCurrentPage: (page: number) => void;
  filter?: string;
};

type SearchPath = {
  path: string;
  getter: (obj: CoverLetterDto) => string | undefined;
};

export const CoverGrid: React.FC<CoverGridProps> = ({
  covers,
  currentPage,
  filter,
  setCurrentPage,
}) => {
  const itemsPerPage = 4;

  const searchableFields: SearchPath[] = [
    {
      path: "title",
      getter: (resume: CoverLetterDto) => resume.title,
    },
    {
      path: "headline",
      getter: (resume: CoverLetterDto) => resume.data.content.company,
    },
    {
      path: "email",
      getter: (resume: CoverLetterDto) => resume.data.basics.email,
    },
    {
      path: "jobTitle",
      getter: (resume: CoverLetterDto) => resume.data.content.jobTitle,
    },
  ];

  const getCurrentPageItems = () => {
    let filteredResumes = covers?.sort((a, b) => sortByDate(a, b, "updatedAt"));

    if (filter) {
      const searchTerm = filter.toLowerCase();
      filteredResumes = covers?.filter((covers) =>
        searchableFields.some(({ getter }) => {
          const fieldValue = getter(covers);
          return fieldValue?.toLowerCase().includes(searchTerm);
        }),
      );
    }

    if (currentPage === 1) {
      return filteredResumes?.slice(0, 3) ?? [];
    }

    const startIndex = 3 + (currentPage - 2) * 4;
    return filteredResumes?.slice(startIndex, startIndex + 4) ?? [];
  };

  const totalFilteredItems = filter
    ? covers?.filter((cover) =>
        searchableFields.some(({ getter }) =>
          getter(cover)?.toLowerCase().includes(filter.toLowerCase()),
        ),
      ).length
    : covers?.length ?? 0;

  const totalPages = Math.ceil(((totalFilteredItems as number) + 1) / itemsPerPage);

  const [lastId, setLastId] = useState("");

  const openDownloadPdf = usePreviewStore((state) => state.openDownloadPdf);
  const handleDownloadPdf = usePreviewStore((state) => state.handleOpenDownloadPdf);

  const { printCover, loading } = usePrintCover();

  const onPrint = async () => {
    if (lastId === "") return;

    const { url } = await printCover({ id: lastId });

    triggerDownload(url);
  };

  return (
    <div className="flex flex-col">
      <div className="grid min-h-[768px] grow grid-cols-1 gap-4 sm:gap-6 lg:grid-cols-2">
        {currentPage === 1 && <NewCoverCard />}
        {getCurrentPageItems()?.map((cover, index: number) => (
          <CoverCard key={cover.id} coverNumber={index + 1} cover={cover} setLastId={setLastId} />
        ))}
        {getCurrentPageItems()?.length < (currentPage === 1 ? 3 : 4) &&
          Array.from({
            length: (currentPage === 1 ? 3 : 4) - (getCurrentPageItems()?.length ?? 0),
          }).map((_, i) => (
            <div key={`placeholder-${i}`} className="bg-transparent sm:h-[372px]" />
          ))}
      </div>
      <div className="mt-auto pt-6">
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage}
        />
      </div>
      <DownloadModal
        type="cover"
        loading={loading}
        isOpen={openDownloadPdf}
        startDownload={onPrint}
        isPremium={true}
        onOpenChange={handleDownloadPdf}
      />
    </div>
  );
};
